import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebtorIndDataDialogComponent } from './debtor-ind-data-dialog.component';
import { PayeePopupModule } from '../../payee-popup/payee-popup.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from '../../payee-form-fields/payee-form-fields.module';
import { PayeeIconModule } from '../../payee-icon/payee-icon.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeeButtonsModule } from '../../payee-buttons/payee-buttons.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    DebtorIndDataDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    PayeePopupModule,
    ReactiveFormsModule,
    PayeeButtonsModule,
    TranslateModule,
  ]
})
export class DebtorIndDataDialogModule { }
