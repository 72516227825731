<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <h1>{{ "WORKFLOW_DIALOGS.MISSING_DATA_GENERAL.TITLE" | translate }}</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      <span>
        {{ "WORKFLOW_DIALOGS.MISSING_DATA_GENERAL.DESCRIPTION" | translate }}
      </span>
    </div>
  </ng-template>

  <form [formGroup]="missingDataGeneral">
    <payee-form-field [width]="'wider'" [height]="'taller'">
      <textarea
        payee-input
        [placeholder]="
          'WORKFLOW_DIALOGS.MISSING_DATA_GENERAL.PLACEHOLDER' | translate
        "
        formControlName="message"
      ></textarea>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <ng-container>
        {{ "WORKFLOW_DIALOGS.MISSING_DATA_GENERAL.SUBMIT" | translate }}
      </ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>
        {{ "WORKFLOW_DIALOGS.MISSING_DATA_GENERAL.CANCEL" | translate }}
      </ng-container>
    </button>
  </ng-template>
</payee-popup>
