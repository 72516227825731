import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SzamlazzhuComponent } from './szamlazzhu.component';
import { InvoicesTableModule } from './invoices-table/invoices-table.module';
import { RouterModule } from '@angular/router';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { InvoiceFormTableModule } from './invoice-form-table/invoice-form-table.module';
import { KsziInfoModule } from './kszi-info/kszi-info.module';
import { InvoiceResendModule } from './invoice-resend/invoice-resend.module';
import { FailedJobsModule } from './failed-jobs/failed-jobs.module';
import { MissingRefIdsModule } from './missing-ref-ids/missing-ref-ids.module';



@NgModule({
  declarations: [
    SzamlazzhuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    InvoicesTableModule,
    InvoiceFormTableModule,
    PayeeButtonsModule,
    KsziInfoModule,
    InvoiceResendModule,
    KsziInfoModule,
    FailedJobsModule,
    MissingRefIdsModule,
  ]
})
export class SzamlazzhuModule { }
