<header class="flex vcenter">
  <button color="grey-light" payee-icon-button (click)="logout()">
    <payee-icon name="logout-grey"></payee-icon>
  </button>
  <button payee-button color="grey-light" class="name-button">
    {{ userName }}
  </button>
</header>

<main class="flex column">
  <h1>Termékek</h1>

  <div class="flex vcenter country-selector-container">
    <payee-form-field>
      <payee-autocomplete
        [options]="countryOptions"
        [displayWith]="countryDisplayWith"
        [formControl]="countryControl"
      ></payee-autocomplete>
    </payee-form-field>

    <payee-toggle
      [selected]="selectedCountry?.is_form_active"
      (selectionChanged)="setCountryIsFormActive($event)"
      text="Form engedélyezése"
    >
    </payee-toggle>
  </div>

  <button payee-button (click)="editProduct()">Termék létrehozása</button>

  <table
    *ngIf="!loading; else loadingSpinner"
    mat-table
    matSort
    [dataSource]="dataSource"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Termék neve</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Termék típusa</th>
      <td mat-cell *matCellDef="let element">
        <!-- todo human readable type -->
        {{ element.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="currency_iso">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Termék pénzneme</th>
      <td mat-cell *matCellDef="let element">
        {{ element.currency_iso }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activation">
      <th mat-header-cell mat-sort-header="is_active" *matHeaderCellDef>
        Termék aktiválása
      </th>
      <td mat-cell *matCellDef="let element">
        <payee-toggle
          [selected]="element.is_active"
          [disabled]="!canActivate(element)"
          (selectionChanged)="setProductIsActive($event, element)"
        ></payee-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          payee-button
          height="short"
          width="short"
          (click)="editProduct(element)"
        >
          Szerkesztés
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          *ngIf="element.is_deletable"
          payee-icon-button
          color="white"
          [isBorderButton]="true"
          (click)="deleteProduct(element)"
        >
          <payee-icon name="trash-red"></payee-icon>
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="main-header"
    ></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
  </table>
</main>

<ng-template #loadingSpinner>
  <mat-progress-spinner
    class="loading-spinner"
    color="accent"
    diameter="100"
    mode="indeterminate"
  ></mat-progress-spinner>
</ng-template>
