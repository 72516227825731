import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductPricesComponent } from './product-prices.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { MatTableModule } from '@angular/material/table';
import { EditProductDialogComponent } from './edit-product-dialog/edit-product-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { PayeeToggleModule } from 'src/app/shared/payee-toggle/payee-toggle.module';
import { MatSortModule } from '@angular/material/sort';
import { DeleteProductDialogComponent } from './delete-product-dialog/delete-product-dialog.component';


@NgModule({
  declarations: [
    ProductPricesComponent,
    EditProductDialogComponent,
    DeleteProductDialogComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    PayeeButtonsModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    PayeePopupModule,
    ReactiveFormsModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    PayeeToggleModule,
  ]
})
export class ProductPricesModule { }
