<payee-popup title="Termék törlése">
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      (click)="submit()"
    >
      Törlés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close>
      Mégse
    </button>
  </ng-template>
</payee-popup>
