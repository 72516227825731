import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type ClientDataTableItem = {
  id: string;
  name: string;
  tax_number: string | null;
  open_cases: number;
  closed_cases: number;
  party_type_type: 'org' | 'ind' | null;
  party_type_id: number | null;
  default_legal_relationship_id: number;
  default_claim_type_id: number;
  pwc_sync_from: string | null;
  can_start_new_pwc_sync: boolean;
  is_deletable:
  true
  | 'client-has-users'
  | 'has-started-cases';
  address: {
    country: string | null;
    postcode: string | null;
    settlement: string | null;
    street: string | null;
  };
  bankAccount: {
    account_number: string | null;
    iban: string | null;
    bank_name: string | null;
    account_holder_name: string | null;
    bic: string | null;
  };
};

export type ClientDataTableParams = {
  name?: string;
  page?: number;
  per_page?: number;
  sort_by?: 'name'
  | 'tax_number'
  | 'open_cases'
  | 'closed_cases';
  sort_direction?: 'asc' | 'desc';
};

export type ClientDataTableHeader = {
  title: string;
  align: 'start' | 'center';
  key?: keyof ClientDataTableItem;
};

export type ClientDataTableResponse = {
  headers: ClientDataTableHeader[];
  sortBy: unknown[];
  data: ClientDataTableItem[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
  };
};

export type ListClientsParams = {
  name?: string;
};

export type ListClientItem = {
  id: string;
  name: string;
};

export type ListClientsResponse = {
  clients: ListClientItem[];
};

export type UpdateCommonClientParams = {
  party_type_id: number;
  default_legal_relationship_id?: number;
  default_claim_type_id?: number;
  pwc_sync_from?: string;
  email?: string;
  phone?: string;
  name?: string;
  address?: {
    country_id: number;
    settlement: string;
    postcode: string;
    street: string;
  };
  bankAccount?: {
    bic?: string;
    bank_name?: string;
    account_number?: string;
    iban?: string;
    account_holder_name: string;
  };
  tax_number?: string | null;
};

export type UpdateOrgClientParams = UpdateCommonClientParams & {
  representative?: {
    email?: string;
    phone?: string;
    representative_type_id: number;
    address?: {
      country_id?: number;
      postcode?: string;
      settlement?: string;
      street?: string;
    };
  };
};

export type UpdateIndClientParams = UpdateCommonClientParams;

export type UpdateClientParams = UpdateOrgClientParams | UpdateIndClientParams;

export type CreateOrgClientParams = {
  type: 'org';
  tax_number: string;
  party_type_id: number;
};

export type CreateIndClientParams = {
  type: 'ind';
  name: string;
  tax_number: string;
  party_type_id: number;
  address: {
    country: 'HU';
    settlement: string;
    postcode: string;
    street: string;
  };
};

export type CreateClientParams = CreateOrgClientParams | CreateIndClientParams;

export type ClientDataTableDetailsResponse = {
  client: {
    bank_account: null | {
      id: number;
      account_holder_name: string;
      account_number: null | string;
      iban: null | string;
      bic: null | string;
      bank_name: null | string;
    };
    address: null | string;
  };
  users: {
    name: string;
    email: string;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  async getClientsDataTable(params: ClientDataTableParams): Promise<ClientDataTableResponse> {
    const url = environment.baseUrl + '/api/client-datatable';
    const result = await axios.get<ClientDataTableResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }

  async getClientsDataTableDetails(clientId: string): Promise<ClientDataTableDetailsResponse> {
    const url = `${environment.baseUrl}/api/client-datatable/${clientId}/details`;
    const result = await axios.get<ClientDataTableDetailsResponse>(url);

    return result.data;
  }

  async listClients(params: ListClientsParams): Promise<ListClientsResponse> {
    const url = environment.baseUrl + '/api/client';
    const result = await axios.get<ListClientsResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }

  async updateClient(clientId: string, params: UpdateClientParams): Promise<ClientDataTableItem> {
    const url = environment.baseUrl + `/api/client/${clientId}`;
    const result = await axios.patch<ClientDataTableItem>(
      url,
      params,
    );

    return result.data;
  }

  async createClient(params: CreateClientParams): Promise<ClientDataTableItem> {
    const url = environment.baseUrl + `/api/client`;
    const result = await axios.post<ClientDataTableItem>(url, params);

    return result.data;
  }

  async deleteClient(id: string): Promise<void> {
    const url = environment.baseUrl + `/api/client/${id}`;
    await axios.delete<ClientDataTableItem>(url);
  }

  async getClient(id: string): Promise<ListClientItem> {
    const url = environment.baseUrl + `/api/client/${id}`;
    const result = await axios.get<ListClientItem>(url);

    return result.data;
  }
}
