export type EnumDescriptorColumn = {
  name: string;
  type: 'int' | 'string' | 'enum' | 'array' | 'boolean' | 'external';
  values?: string[];
  externalModel?: string;
  readonly: boolean;
};

export type EnumDescriptor = {
  model: string;
  columns: EnumDescriptorColumn[];
};

export const claimType: EnumDescriptor = {
  model: 'App\\Models\\ClaimType',
  columns: [
    {
      name: 'id',
      type: 'int',
      readonly: true,
    },
    {
      name: 'label',
      type: 'string',
      readonly: false,
    },
    {
      name: 'code',
      type: 'int',
      readonly: true,
    },
    {
      name: 'name',
      type: 'string',
      readonly: false,
    }],
};

export const legalRelationship: EnumDescriptor = {
  model: 'App\\Models\\LegalRelationship',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  },
  {
    name: 'name',
    type: 'string',
    readonly: false,
  },
  {
    name: 'label',
    type: 'int',
    readonly: false,
  },
  {
    name: 'code',
    type: 'string',
    readonly: true,
  }],
};

export const partyType: EnumDescriptor = {
  model: 'App\\Models\\PartyType',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  },
  {
    name: 'type',
    type: 'enum',
    readonly: false,
    values: [
      'org',
      'ind',
    ],
  },
  {
    name: 'registration_office_id',
    type: 'external',
    readonly: false,
    externalModel: 'App\\Models\\RegistrationOffice',
  },
  {
    name: 'name',
    type: 'string',
    readonly: true,
  },
  {
    name: 'label',
    type: 'int',
    readonly: false,
  },
  {
    name: 'match_reference',
    type: 'array',
    readonly: false,
  }],
};

export const representativeType: EnumDescriptor = {
  model: 'App\\Models\\RepresentativeType',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  },
  {
    name: 'code',
    type: 'string',
    readonly: true,
  },
  {
    name: 'is_agent',
    type: 'boolean',
    readonly: false,
  },
  {
    name: 'name',
    type: 'string',
    readonly: false,
  },
  {
    name: 'label',
    type: 'int',
    readonly: false,
  },
  {
    name: 'type',
    type: 'enum',
    readonly: false,
    values: [
      'org',
      'ind',
    ],
  },
  {
    name: 'match_reference',
    type: 'array',
    readonly: false,
  }],
};

export const registrationOffice: EnumDescriptor = {
  model: 'App\\Models\\RegistrationOffice',
  columns: [{
    name: 'id',
    type: 'int',
    readonly: true,
  },
  {
    name: 'internal_id',
    type: 'string',
    readonly: true,
  },
  {
    name: 'name',
    type: 'string',
    readonly: false,
  },
  {
    name: 'label',
    type: 'int',
    readonly: false,
  },
  {
    name: 'country_iso',
    type: 'enum',
    readonly: false,
    values: [
      'HU',
    ],
  }],
};

export const fees: EnumDescriptor = {
  columns: [
    {
      name: 'id',
      readonly: true,
      type: 'int',
    },
    {
      name: 'label',
      readonly: false,
      type: 'string',
    },
    {
      name: 'fee',
      readonly: false,
      type: 'int',
    },
    {
      name: 'min',
      readonly: false,
      type: 'int',
    },
    {
      name: 'max',
      readonly: false,
      type: 'int',
    },
    {
      name: 'type',
      readonly: true,
      type: 'enum',
      values: [
        'amount',
        'percentage',
      ],
    },
    {
      name: 'is_net',
      readonly: false,
      type: 'boolean',
    },
    {
      name: 'payment_occurrence',
      readonly: true,
      type: 'enum',
      values: [
        'start',
        'closed-success',
      ],
    },
    {
      name: 'fee_type',
      readonly: true,
      type: 'enum',
      values: [
        'indirect',
        'payee',
      ],
    },
  ],
  model: 'App\\Models\\Fee',
};

export const vats: EnumDescriptor = {
  columns: [
    {
      name: 'id',
      readonly: true,
      type: 'int',
    },
    {
      name: 'internal_id',
      readonly: true,
      type: 'string',
    },
    {
      name: 'percentage',
      readonly: false,
      type: 'string',
    },
    {
      name: 'label',
      readonly: false,
      type: 'string',
    },
    {
      name: 'country_iso',
      readonly: true,
      type: 'string',
    },
  ],
  model: 'App\\Models\\Vat',
};

export const enumDescriptors = [
  claimType,
  legalRelationship,
  partyType,
  representativeType,
  registrationOffice,
  fees,
  vats,
];
