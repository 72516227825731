<div
  (click)="onClick(!selected)"
  class="container"
  [class.enabled]="!disabled"
>
  <div class="content">
    <div class="toggle-icon">
      <payee-icon
        *ngIf="selected && !disabled"
        [name]="'toggle-on'"
      ></payee-icon>
      <payee-icon
        *ngIf="!selected && !disabled"
        name="toggle-off"
      ></payee-icon>
      <payee-icon
        *ngIf="selected && disabled"
        name="toggle-on-disabled"
      ></payee-icon>
      <payee-icon
        *ngIf="!selected && disabled"
        name="toggle-off"
      ></payee-icon>
    </div>
    <div *ngIf="text">
      <payee-icon name="add-claim"></payee-icon>
    </div>
    <div
      class="text"
      [class.selected]="selected"
    >
      {{ text }}
    </div>
  </div>
</div>
