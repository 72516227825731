<payee-popup
  title="Opcionális követelések szerkesztése"
  [onCancel]="cancel"
  [onSubmit]="submit"
>
  <form [formGroup]="form">
    <div class="flex column">
      <h2>Iktatószám: {{ data.payee_case_reference_id }}</h2>
      <span>
        Alapkövetelés eredeti összege:
        {{ original_amount | number : "1.0" : "hu" }}
        {{ data.claim.currency }}
      </span>
      <span>
        Alapkövetelés fennálló összege:
        {{ remaining_amount | number : "1.0" : "hu" }}
        {{ data.claim.currency }}
      </span>
    </div>
    <payee-form-field *ngIf="interest_claim" width="wide">
      <span payee-label>Kamat összege</span>
      <span payee-suffix>{{ data.claim.currency }}</span>
      <input payee-input formControlName="interest_claim" />
      <ng-container
        payee-error
        *ngIf="interest_claim.invalid && interest_claim.dirty"
      >
        <span *ngIf="interest_claim.hasError('min')">
          Az összeg pozitív kell legyen
        </span>
      </ng-container>
    </payee-form-field>

    <form *ngIf="flat_rate_cost_claims" [formGroup]="flat_rate_cost_claims">
      <div
        *ngFor="
          let entry of flat_rate_cost_claims.controls | keyvalue;
          let i = index
        "
        class="flex column"
      >
        <h2>{{ i + 1 }}. követelés</h2>
        <span>
          Alapkövetelés eredeti összege:
          {{
            claims[entry.key]?.parent.original_amount | number : "1.0" : "hu"
          }}
          {{ data.claim.currency }}
        </span>
        <span>
          Alapkövetelés fizetési határideje:
          {{ claims[entry.key]?.parent.due_date_at }}
        </span>
        <payee-form-field width="wide">
          <span payee-label>40 eur összege</span>
          <input payee-input [formControl]="entry.value" />
          <span payee-suffix>{{ data.claim.currency }}</span>
          <ng-container
            payee-error
            *ngIf="entry.value.invalid && entry.value.dirty"
          >
            <span *ngIf="entry.value.hasError('min')">
              Az összeg pozitív kell legyen
            </span>
          </ng-container>
        </payee-form-field>
      </div>
    </form>
  </form>
</payee-popup>
