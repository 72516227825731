<payee-dropdown
  class="lang-dropdown"
  [options]="langs"
  [formControl]="languageControl"
  width="max"
>
  <ng-template #menuButtonTemplate>
    <button
      payee-button
      class="lang-menu-button"
      [color]="color"
      [isBorderButton]="isBorderButton"
      [isRoundButton]="false"
    >
      <img
        width="24px"
        height="24px"
        src="assets/shared/global-images/flags/{{ currentLang }}.svg"
        [alt]="currentLang"
      />
    </button>
  </ng-template>

  <ng-template #itemDisplayTemplate let-option>
    <img
      width="24px"
      height="24px"
      src="assets/shared/global-images/flags/{{ option }}.svg"
      [alt]="option"
    />
  </ng-template>
</payee-dropdown>
