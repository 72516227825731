<payee-popup
  [title]="'WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.TITLE' | translate"
  [description]="
    'WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.DESCRIPTION' | translate
  "
>
  <ul class="file-examples top-margin-10">
    <li>
      {{
        "WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.EXAMPLES.CONTRACT" | translate
      }}
    </li>
    <li>
      {{ "WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.EXAMPLES.TERMS" | translate }}
    </li>
    <li>
      {{
        "WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.EXAMPLES.ORDER_CONFIRMATION"
          | translate
      }}
    </li>
    <li>
      {{
        "WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.EXAMPLES.PERFORMANCE_CERTIFICATE"
          | translate
      }}
    </li>
  </ul>

  <payee-dropzone
    class="top-margin-25"
    title=""
    [multiple]="true"
    [description]="
      'WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.DROPZONE_DESCRIPTION' | translate
    "
    (dropped)="filesDropped($event)"
  ></payee-dropzone>

  <div *ngIf="files.length > 0" class="flex column files top-margin-15">
    <div *ngFor="let file of files; let i = index" class="file flex vcenter">
      <payee-icon name="upload-paper"></payee-icon>
      <span>{{ file.name }}</span>
      <button
        type="button"
        payee-icon-button
        color="white"
        (click)="files.splice(i, 1)"
      >
        <payee-icon name="trash-red"></payee-icon>
      </button>
    </div>
  </div>

  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" (click)="submit()">
      <div class="flex vcenter hcenter">
        <span>{{
          "WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.SUBMIT" | translate
        }}</span>
        <payee-icon height="10px" name="arrow-right-white"></payee-icon>
      </div>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      color="white"
      [isBorderButton]="true"
      width="wide"
      mat-dialog-close
    >
      <div class="flex vcenter hcenter">
        <span>{{
          "WORKFLOW_DIALOGS.CLIENT_UPLOAD_CONTRACT.CANCEL" | translate
        }}</span>
        <payee-icon height="10px" name="arrow-right-grey"></payee-icon>
      </div>
    </button>
  </ng-template>
</payee-popup>
