import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingRefIdsComponent } from './missing-ref-ids.component';
import { MatTableModule } from '@angular/material/table';
import { SetInvoiceDialogComponent } from './set-invoice-dialog/set-invoice-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';



@NgModule({
  declarations: [
    MissingRefIdsComponent,
    SetInvoiceDialogComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    PayeeButtonsModule,
    PayeePopupModule,
    MatRadioModule,
  ]
})
export class MissingRefIdsModule { }
