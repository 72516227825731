import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixMissingOptionalClaimsDialogComponent } from './fix-missing-optional-claims-dialog.component';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';



@NgModule({
  declarations: [
    FixMissingOptionalClaimsDialogComponent
  ],
  imports: [
    CommonModule,
    PayeePopupModule,
    ReactiveFormsModule,
    PayeeFormFieldsModule,
  ]
})
export class FixMissingOptionalClaimsDialogModule { }
