import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AxiosError } from 'axios';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { ClientsService, ListClientItem } from 'src/app/services/clients/clients.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { RolesService } from 'src/app/services/roles/roles.service';
import { UserDataTableItem, UsersService } from 'src/app/services/users/users.service';

type Country = {
  iso: string;
  name: string;
};

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;
  readonly clientFilter: FormControl;
  clientsLoading = false;
  filteredClients: ListClientItem[] = [];
  roleOptions: { name: string; }[] = [];
  readonly attorneyCountryOptions: Country[] = [
    { iso: 'HU', name: 'Magyarország' },
    { iso: 'SK', name: 'Szlovákia' },
  ];

  submitLoading = false;
  initLoading = false;

  private readonly destroy = new Subject<void>();

  get email(): FormControl { return this.formGroup.get('email') as FormControl; }
  get name(): FormControl { return this.formGroup.get('name') as FormControl; }
  get phone(): FormControl { return this.formGroup.get('phone') as FormControl; }
  get roles(): FormControl { return this.formGroup.get('roles') as FormControl; }
  get clients(): FormControl { return this.formGroup.get('clients') as FormControl; }
  get attorneyCountries(): FormControl { return this.formGroup.get('attorneyCountries') as FormControl; }

  get userCanViewAttorney(): boolean { return ['attorney', 'Super-Admin'].some(role => this.roles.value.includes(role)); }

  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private user: UserDataTableItem,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private helpersService: HelpersService,
    private rolesService: RolesService,
    private clientsService: ClientsService,
    private usersService: UsersService,
  ) {
    this.clientFilter = this.fb.control('');
    this.formGroup = this.fb.group({
      email: [this.user.email, [Validators.required, Validators.email]],
      name: [this.user.user_name, Validators.required],
      phone: [this.user.phone_number, this.helpersService.phoneNumberValidator],
      roles: [this.user.roles.map(r => r.name), Validators.minLength(1)],
      attorneyCountries: [this.user.attorney_countries],
      clients: [this.user.clients.concat()],
    });
  }

  async ngOnInit(): Promise<void> {
    this.clientFilter.valueChanges
      .pipe(
        takeUntil(this.destroy),
        filter((v?: string) => !!v && v.length > 2),
        debounceTime(1000))
      .subscribe({
        next: async (filter: string) => {
          try {
            this.clientsLoading = true;
            const result = await this.clientsService.listClients({
              name: filter,
            });
            this.filteredClients = result.clients;
          } catch (error) {
            console.error('Error while loading clients', error);
          } finally {
            this.clientsLoading = false;
          }
        },
      });
    try {
      this.initLoading = true;

      const roleResult = await this.rolesService.getRoles();
      this.roleOptions = roleResult.roles;
    } catch (error) {
      console.error('Error while initializing', error);
    } finally {
      this.initLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  isRoleSelected(role: string): boolean {
    return this.roles.value.includes(role);
  }

  toggleCountry = (event: MatCheckboxChange, country: Country): void => {
    const checkboxState: boolean = event.checked;
    const countries: Country[] = this.attorneyCountries.value;

    if (checkboxState && !this.isCountrySelected(country)) {
      countries.push(country);
    } else if (!checkboxState && this.isCountrySelected(country)) {
      countries.splice(countries.findIndex(c => c.iso === country.iso), 1);
    }
    this.attorneyCountries.patchValue(countries);
  };

  isCountrySelected = (country: Country): boolean => {
    return !!this.attorneyCountries.value.find(c => c.iso === country.iso);
  };

  toggleRole(event: MatCheckboxChange, role: string): void {
    const checkboxState: boolean = event.checked;
    const roles: string[] = this.roles.value;

    if (checkboxState && !roles.includes(role)) {
      roles.push(role);
    }
    if (!checkboxState && roles.includes(role)) {
      roles.splice(roles.indexOf(role), 1);
    }
    this.roles.patchValue(roles);
  }

  selectClient(client: ListClientItem): void {
    const clients: ListClientItem[] = this.clients.value;
    if (clients.find(c => c.id === client.id)) {
      return;
    }
    clients.push(client);
    this.clients.patchValue(clients);
  }

  unselectClient(client: ListClientItem): void {
    const clients: ListClientItem[] = this.clients.value;
    const index = clients.findIndex(c => c.id === client.id);
    console.log('Unselecting client', {
      client,
      clients: clients.concat(),
      index,
    });
    if (index === -1) {
      return;
    }
    clients.splice(index, 1);
    this.clients.patchValue(clients);
  }

  clearClientFilter(): void {
    this.filteredClients = [];
    this.clientFilter.patchValue('');
  }

  async submit() {
    if (this.formGroup.invalid || this.submitLoading) {
      return;
    }

    try {
      this.submitLoading = true;

      await this.usersService.updateUser(this.user.user_uuid, {
        client_ids: this.clients.value.map(c => c.id),
        email: this.email.value,
        name: this.name.value,
        roles: this.roles.value.filter(r => this.roleOptions.find(rop => rop.name === r)),
        phone: this.phone.value || undefined,
        attorney_countries: this.attorneyCountries.value.map(c => c.iso),
      });

      this.dialogRef.close(this.email.value);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 422) {
          const errors: Record<string, string[]> = error.response.data.errors;
          Object.entries(errors).forEach(([key, values]) => {
            const formControl = this.formGroup.get(key);
            if (!formControl) {
              this.formGroup.setErrors({
                backend: values[0],
              });
            } else {
              formControl.setErrors({
                backend: values[0],
              });
            }
          });

          return;
        }
      }
      console.error('Error while updating user', error);
      this.snackbar.open('Valami hiba történt mentéskor!', 'OK', {
        duration: 5000,
      });
    } finally {
      this.submitLoading = false;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }
}