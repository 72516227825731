<payee-popup title="Termék szerkesztése">
  <form [formGroup]="formGroup" class="flex column" id="product-form">
    <payee-form-field width="wide">
      <span payee-label>Termék neve</span>
      <input payee-input formControlName="name" />
      <ng-container *ngIf="name.invalid && name.dirty" payee-error>
        <span *ngIf="name.hasError('required')">
          A mező kitöltése kötelező.
        </span>
        <span *ngIf="name.hasError('maxlength')">
          A megadott szöveg túllépi a megengedett karakterszámot.
        </span>
      </ng-container>
    </payee-form-field>
    <div class="flex">
      <payee-form-field width="short">
        <span payee-label>Termék típusa</span>
        <payee-dropdown
          formControlName="type"
          [options]="productTypeOptions"
        ></payee-dropdown>
      </payee-form-field>
      <payee-form-field width="short">
        <span payee-label>Termék pénzneme</span>
        <input payee-input formControlName="currency_iso" />
        <ng-container
          *ngIf="currency_iso.invalid && currency_iso.dirty"
          payee-error
        >
          <span *ngIf="currency_iso.hasError('required')">
            A mező kitöltése kötelező.
          </span>
          <span
            *ngIf="name.hasError('maxlength') || name.hasError('minlength')"
          >
            A pénznem hossza 3 karakter kell legyen
          </span>
        </ng-container>
      </payee-form-field>
    </div>

    <form
      *ngFor="let feeForm of fees.controls; let i = index"
      [formGroup]="feeForm"
    >
      <div class="flex vcenter fee-title">
        <h2>{{ i + 1 }}. díj</h2>
        <button
          payee-icon-button
          color="white"
          [isBorderButton]="true"
          (click)="removeFee(i)"
        >
          <payee-icon name="trash-red"></payee-icon>
        </button>
      </div>
      <payee-form-field width="wide">
        <span payee-label>Díj neve</span>
        <input payee-input formControlName="label" />
        <ng-container
          *ngIf="feeForm.get('label').invalid && feeForm.get('label').dirty"
          payee-error
        >
          <span *ngIf="feeForm.get('label').hasError('required')">
            A mező kitöltése kötelező.
          </span>
          <span *ngIf="name.hasError('maxlength')">
            A megadott szöveg túllépi a megengedett karakterszámot.
          </span>
        </ng-container>
      </payee-form-field>
      <div class="flex">
        <payee-form-field width="short">
          <span payee-label>Díj mértéke</span>
          <input payee-input formControlName="fee" />
          <ng-container
            *ngIf="feeForm.get('fee').invalid && feeForm.get('fee').dirty"
            payee-error
          >
            <span *ngIf="feeForm.get('fee').hasError('required')">
              A mező kitöltése kötelező.
            </span>
          </ng-container>
        </payee-form-field>
        <payee-form-field width="short">
          <span payee-label>Díj típusa</span>
          <payee-dropdown
            width="short"
            formControlName="type"
            [options]="feeTypeOptions"
          ></payee-dropdown>
        </payee-form-field>
      </div>
      <div class="flex">
        <payee-form-field
          *ngIf="feeForm.value.type === 'percentage'"
          width="short"
        >
          <span payee-label>Minimum díj</span>
          <input payee-input formControlName="min" />
        </payee-form-field>
        <payee-form-field
          *ngIf="feeForm.value.type === 'percentage'"
          width="short"
        >
          <span payee-label>Maximum díj</span>
          <input payee-input formControlName="max" />
        </payee-form-field>
      </div>
      <payee-toggle
        [selected]="feeForm.value.is_net"
        (selectionChanged)="feeForm.patchValue({ is_net: $event })"
        text="Díj összege nettó"
      >
      </payee-toggle>
      <div class="flex">
        <payee-form-field width="short">
          <span payee-label>Díj behajtási ideje</span>
          <payee-dropdown
            width="short"
            formControlName="payment_occurrence"
            [options]="feePaymentOccurrenceOptions"
          ></payee-dropdown>
        </payee-form-field>
        <payee-form-field width="short">
          <span payee-label>Díj forrása</span>
          <payee-dropdown
            width="short"
            formControlName="fee_type"
            [options]="feeFeeTypeOptions"
          ></payee-dropdown>
        </payee-form-field>
      </div>
      <!-- TODO -->
      <payee-form-field width="wide">
        <span payee-label>Díj ÁFA</span>
        <payee-dropdown
          width="wide"
          formControlName="vat_id"
          [options]="vatOptions"
          [displayWith]="vatDisplayedWith"
        ></payee-dropdown>
      </payee-form-field>
    </form>

    <button payee-button width="max" (click)="addFee()">Díj hozzáadása</button>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      width="wide"
      [disabled]="submitLoading"
      (click)="submit()"
    >
      Mentés
    </button>
  </ng-template>
  <ng-template #payeePopupCancelTemplate>
    <button payee-button color="alert-red" width="wide" mat-dialog-close>
      Mégse
    </button>
  </ng-template>
</payee-popup>
