import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSource } from 'src/types';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { filter, first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export type RegisterParams = {
  email: string;
  is_terms_accepted: boolean;
  is_privacy_accepted: boolean;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
};

export type RegisterResponse = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};

export type VerifyEmailParams = {
  name: string;
  password: string;
  is_newsletter_enabled: boolean;
  token: string;
};

export type SendPasswordResetEmailParams = {
  email: string;
};

export type ResetPasswordParams = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export type ForceResetPasswordParams = {
  old_password: string;
  password: string;
  password_confirmation: string;
};

export type LoginWithShortTokenResponse = {
  access_token: string;
  expires_in: number;
  origin: UserSource;
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private refreshingTokens = new BehaviorSubject<boolean>(false);
  private requestsWaiting = new BehaviorSubject<number>(0);
  private authStorage: Storage = localStorage;

  private get accessToken(): string | null { return this.authStorage.getItem('access_token'); }
  private set accessToken(accessToken: string | null) {
    if (accessToken) {
      this.authStorage.setItem('access_token', accessToken);
    } else {
      this.authStorage.removeItem('access_token');
    }
  }
  private get refreshToken(): string | null { return this.authStorage.getItem('refresh_token'); }
  private set refreshToken(refreshToken: string | null) {
    if (refreshToken) {
      this.authStorage.setItem('refresh_token', refreshToken);
    } else {
      this.authStorage.removeItem('refresh_token');
    }
  }
  private get expiresAt(): string | null { return this.authStorage.getItem('expires_at'); }
  private set expiresAt(expiresAt: string | null) {
    if (expiresAt) {
      this.authStorage.setItem('expires_at', expiresAt);
    } else {
      this.authStorage.removeItem('expires_at');
    }
  }

  private _origin: UserSource | null;
  get origin(): UserSource | null { return this._origin; };

  private get next(): string | null { return localStorage.getItem('next') || null; }

  authSubject: BehaviorSubject<boolean>;
  get isAuthenticated(): boolean { return this.authSubject.value; };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.setNext(this.next);
    this.setDefaultPersistance();
    this.setDefaultUserOrigin();
    this.setRequestInterceptor();
    this.setResponseInterceptor();

    this.authSubject = new BehaviorSubject<boolean>(!!this.accessToken);
    this.setAfterLoginRedirectObserver();
  }

  setPersistance(persistance: 'session' | 'local'): void {
    if (persistance === 'session') {
      this.authStorage = sessionStorage;
    } else {
      this.authStorage = localStorage;
    }
  }

  setTokens(data: {
    access_token: string,
    refresh_token: string | null,
    expires_in: number,
    id_token: string | null,
  }): void {
    this.accessToken = data.access_token;
    this.refreshToken = data.refresh_token;
    this.expiresAt = (new Date().valueOf() + data.expires_in * 1000).toString();
    this.authSubject.next(true);
  };

  clearTokens(): void {
    this.refreshToken = null;
    this.accessToken = null;
    this.expiresAt = null;
  }

  async login(email: string, password: string): Promise<void> {
    this.clearTokens();

    const url = environment.baseUrl + `/api/auth/login`;
    const result = await axios.post(url, {
      email,
      password,
    });

    const {
      access_token,
      refresh_token,
      expires_in,
    } = result.data;

    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.expiresAt = (new Date().valueOf() + expires_in * 1000).toString();
    if (!this.next) {
      this.setNext(environment.afterLoginPath);
    }
    this.authSubject.next(true);
  }

  async loginWithShortToken(shortToken: string): Promise<void> {
    const url = `${environment.baseUrl}/api/auth/login-with-short-token/${shortToken}`;
    const result = await axios.post<LoginWithShortTokenResponse>(url);

    this.clearTokens();
    this.setPersistance('session');
    this.setTokens({
      access_token: result.data.access_token,
      expires_in: result.data.expires_in,
      id_token: null,
      refresh_token: null,
    });
    this.setOrigin(result.data.origin);
  }

  // Login for partners
  async loginWithCustomToken(customToken: string, origin: UserSource = 'ORGANIC'): Promise<void> {
    try {
      throw new Error('Unimplemented');
      this.setOrigin(origin);

      // this.router.navigate(['user/cases']);
    } catch (error) {
      throw error;
    }
  }

  async createPayeeAccountFromCashbook(ssoToken: string): Promise<string> {
    throw new Error('Unimplemented');
  }

  async register(params: RegisterParams): Promise<void> {
    const url = environment.baseUrl + `/api/auth/register`;
    const response = await axios.post<RegisterResponse>(url, params);
    const {
      access_token,
      expires_in,
      refresh_token,
    } = response.data;

    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.expiresAt = (new Date().valueOf() + expires_in * 1000).toString();
    this.authSubject.next(true);

    await this.router.navigateByUrl('verify-email');
  }

  async sendPasswordResetEmail(params: SendPasswordResetEmailParams): Promise<void> {
    try {
      const url = environment.baseUrl + `/api/auth/send-reset-password-email`;
      await axios.post(url, params);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 429) {
        this.snackbar.open('Próbáld újra késöbb!', 'OK', {
          duration: 10000,
        });
      } else {
        console.error('Unknown error while sending password reset email!', error);
        this.snackbar.open('Valami hiba történt', 'OK', {
          duration: 10000,
        });
      }
      throw error;
    }
  }

  async resendVerifyEmail(): Promise<void> {
    const url = environment.baseUrl + `/api/auth/send-email-verification`;
    await axios.post(url);
  }

  async logout(): Promise<void> {
    if (!this.isAuthenticated) {
      this.clearTokens();
      await this.router.navigateByUrl('login');
      return;
    }

    this.refreshToken = null;

    if (this.accessToken) {
      const url = environment.baseUrl + `/api/auth/logout`;
      await axios.post(url).catch(e => console.warn('Error while logging out', e));
    }

    this.authSubject.next(false);
    this.clearTokens();

    await this.router.navigateByUrl('login');
  }

  async resetPassword(params: ResetPasswordParams, redirectTo = 'forgot-password-new-password-success'): Promise<void> {
    const url = environment.baseUrl + `/api/auth/reset-password`;
    await axios.post(url, params);

    await this.router.navigateByUrl(redirectTo);
  }

  async forceResetPassword(params: ForceResetPasswordParams): Promise<void> {
    const url = environment.baseUrl + `/api/auth/force-reset-password`;
    await axios.post(url, params);
  }

  async finalizeSignup(params: VerifyEmailParams, redirectTo = 'user/add-new-client-onboarding'): Promise<void> {
    const url = environment.baseUrl + `/api/auth/verify-email`;
    await axios.post(url, params);

    await this.router.navigateByUrl(redirectTo);
  }

  async enable2FA(): Promise<void> {
    const url = environment.baseUrl + `/api/auth/2fa/register`;

    await axios.post(url);
    this.authStorage.setItem('next', this.router.url);
    this.router.navigateByUrl('2fa/verify');
  }

  async submitMFACode(code: string): Promise<void> {
    const url = new URL(environment.baseUrl + `/api/auth/2fa/token`);
    url.searchParams.append('one_time_password', code);

    await axios.post(url.toString());
    this.authSubject.next(true);
    const next = this.authStorage.getItem('next') || '/user';
    this.router.navigateByUrl(next);
  }

  async resendMFACode(): Promise<void> {
    await axios.post(environment.baseUrl + `/api/auth/2fa/resend`);
  };

  getOriginOfPartnerId(partnerId: string): UserSource | null {
    switch (partnerId) {
      case 'szamlazzhu': return 'PLUGIN_SZAMLAZZHU';
      default: return null;
    }
  }

  setOrigin(origin: UserSource | null): void {
    this._origin = origin;
    if (origin === null) {
      this.authStorage.removeItem('user-origin');
    } else {
      this.authStorage.setItem('user-origin', origin);
    }
  }

  getUserOrigin() {
    return this.authStorage.getItem('user-origin') as UserSource;
  }

  setNext(url: string | null): void {
    if (!url) {
      localStorage.removeItem('next');
    } else {
      localStorage.setItem('next', url);
    }
  }

  private setDefaultUserOrigin(): void {
    let userOrigin = sessionStorage.getItem('user-origin') as UserSource;
    if (userOrigin) {
      this.setOrigin(userOrigin);
    } else {
      userOrigin = this.authStorage.getItem('user-origin') as UserSource;
      if (userOrigin) {
        this.setOrigin(userOrigin);
      }
    }
  }

  private setRequestInterceptor(client: AxiosInstance = axios): void {
    client.interceptors.request.use(
      async config => {
        if (this.refreshingTokens.value && !config.data?.refresh_token) {
          this.requestsWaiting.next(this.requestsWaiting.value + 1);
          await this.refreshingTokens.pipe(filter(v => !v), first()).toPromise();
          this.requestsWaiting.next(this.requestsWaiting.value - 1);
        }

        const access_token = this.authStorage.getItem('access_token');
        const user_email = this.authStorage.getItem('user_email'); // for testing purposes

        if (access_token && !user_email) {
          config.headers = {
            'Authorization': `Bearer ${this.accessToken}`,
            'Accept': 'application/json',
          };
        } else if (access_token && user_email) {
          config.headers = {
            'Authorization': `Bearer ${this.accessToken}`,
            'Accept': 'application/json',
            'User-Email': user_email
          };
        } else if (!config.headers) {
          config.headers = {};
        }

        config.headers['Accept-Language'] = this.translate.currentLang;

        return config;
      },
      error => {
        return Promise.reject(error);
      });
  }

  private setResponseInterceptor(
    client: AxiosInstance = axios,
    unauthorizedPolicy: 'retry' | 'login' = 'retry',
  ): void {
    client.interceptors.response.use(
      async res => {
        // if (!this.refreshingTokens.value) {
        //   this.retrying = false;
        //   await this.refreshAccessToken();
        // }

        return res;
      },
      async (error: AxiosError) => {
        try {
          if (error.response?.status === 401) {
            return await this.handleUnauthorized(error, unauthorizedPolicy);
          } else if (error.response?.status === 403) {
            return await this.handleForbidden(error);
          }

          console.error(`Error in axios call ${error.config.url ?? ''}`, {
            body: JSON.stringify(error.config?.data ?? {}),
            params: JSON.stringify(error.config?.params ?? {}),
          });

          return Promise.reject(error);
        } catch (e) {
          return Promise.reject(error);
        }
      },
    );
  }

  private async handleUnauthorized(
    error: AxiosError,
    policy: 'retry' | 'login' = 'retry',
  ): Promise<AxiosResponse> {
    if ((error.response?.data as any)?.code === '2fa-invalid') {
      this.router.navigateByUrl('2fa/verify');
      return Promise.reject(error);
    }
    await this.refreshAccessToken();
    if (this.accessToken && policy === 'retry') {
      const client = axios.create();
      this.setRequestInterceptor(client);
      this.setResponseInterceptor(client, 'login');
      return await client(error.config);
    } else if ((error.response?.data as any)?.user_has_password === false) {
      await this.router.navigateByUrl('forgot-password-get-link');
      return Promise.reject(error);
    } else {
      await this.router.navigateByUrl('login');
      return Promise.reject(error);
    }
  }

  private async handleForbidden(error: AxiosError<any>) {
    if (error.response?.data?.code === 'invalid-role') {
      this.snackbar.open('Nincs jogosultságod az oldal eléréséhez!', 'OK', {
        duration: 10_000,
      });
      this.clearTokens();
      this.router.navigateByUrl('login');
    }
    return Promise.reject(error);
  }

  private async refreshAccessToken(): Promise<void> {
    if (!this.refreshToken) {
      console.warn('No refresh token!');
      this.refreshingTokens.next(false);
      this.dialog.closeAll();
      this.clearTokens();
      await this.logout();
      return;
    }

    if (this.refreshingTokens.value) {
      await this.refreshingTokens.pipe(filter(v => !v), first()).toPromise();
      return;
    }

    this.refreshingTokens.next(true);

    try {
      this.accessToken = null;
      const uninterceptedAxiosInstance = axios.create();
      const res = await uninterceptedAxiosInstance.post(environment.baseUrl + `/api/auth/refresh`, {
        refresh_token: this.refreshToken,
      });

      const {
        access_token,
        refresh_token,
      } = res.data;
      this.accessToken = access_token;
      this.refreshToken = refresh_token;
      this.refreshingTokens.next(false);
    } catch (error) {
      this.refreshingTokens.next(false);
      this.dialog.closeAll();
      this.clearTokens();
      await this.logout();
    }
  }

  private setDefaultPersistance(): void {
    if (this.route.snapshot.queryParams.short_token) {
      this.setPersistance('local');
      this.clearTokens();
      this.setPersistance('session');
      this.clearTokens();
      return;
    }

    this.setPersistance('local');
    if (!this.accessToken) {
      this.clearTokens();
      this.setPersistance('session');
      if (!this.accessToken) {
        this.setPersistance('local');
      }
    } else {
      this.setPersistance('session');
      this.clearTokens();
      this.setPersistance('local');
    }
  }

  private setAfterLoginRedirectObserver(): void {
    this.authSubject
      .subscribe({
        next: isAuthenticated => {
          if (this.next && isAuthenticated) {
            this.router.navigateByUrl(this.next);
            this.setNext(null);
          }
        },
      });
  }
}
