<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <h1 class="top-margin-15">Kedvezmény szerkesztése</h1>
  </ng-template>

  <form [formGroup]="formGroup">
    <!-- country -->
    <payee-form-field width="wide">
      <payee-autocomplete
        [formControl]="country"
        [options]="countryOptions"
        [displayWith]="countryProductDisplayWith"
      ></payee-autocomplete>
    </payee-form-field>

    <!-- product_discountable_type -->
    <payee-dropdown
      formControlName="product_discountable_type"
      [options]="productDiscountableTypeOptions"
      width="wide"
    >
      <span payee-label>Kedvezményezett típusa</span>
      <ng-container payee-error *ngIf="product_discountable_type.dirty">
        <div *ngIf="product_discountable_type.hasError('required')">
          A mező kitöltése kötelező!
        </div>
      </ng-container>
    </payee-dropdown>

    <!-- product_discountable -->
    <payee-form-field width="wide">
      <div payee-label class="flex vcenter">
        <span>Kedvezményezett</span>
        <mat-progress-spinner
          *ngIf="productDiscountableOptionsLoading"
          diameter="24"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>
      <payee-autocomplete
        formControlName="product_discountable"
        [options]="productDiscountableOptions"
        [displayWith]="productDiscountableDisplayWith"
        (searchChange)="setProductDiscountableOptionsSubject.next($event)"
        width="wide"
      >
      </payee-autocomplete>
      <ng-container payee-error *ngIf="product_discountable.dirty">
        <div *ngIf="product_discountable.hasError('required')">
          A mező kitöltése kötelező!
        </div>
      </ng-container>
    </payee-form-field>

    <!-- discount_type -->
    <payee-dropdown
      formControlName="discount_type"
      [options]="discountTypeOptions"
      width="wide"
    >
      <span payee-label>Kedvezmény típusa</span>
      <ng-container payee-error *ngIf="discount_type.dirty">
        <div *ngIf="discount_type.hasError('required')">
          A mező kitöltése kötelező!
        </div>
      </ng-container>
    </payee-dropdown>

    <!-- discount -->
    <payee-form-field width="wide">
      <span payee-label>Kedvezmény mértéke</span>
      <span payee-suffix *ngIf="discount_type.value === 'amount'">
        {{ productDiscount.product.currency_iso }}
      </span>
      <span payee-suffix *ngIf="discount_type.value === 'percentage'"> % </span>
      <input payee-input formControlName="discount" />
    </payee-form-field>

    <!-- product -->
    <payee-dropdown
      formControlName="product"
      [options]="productOptions"
      [displayWith]="productDisplayWith"
      width="wide"
    >
      <span payee-label>Termék</span>
      <ng-container payee-error *ngIf="product.dirty">
        <div *ngIf="product.hasError('required')">
          A mező kitöltése kötelező!
        </div>
      </ng-container>
    </payee-dropdown>

    <!-- fee -->
    <payee-form-field width="wide">
      <div payee-label class="flex vcenter">
        <span>Díj</span>
        <mat-progress-spinner
          *ngIf="feeOptionsLoading"
          diameter="24"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>
      <payee-autocomplete
        formControlName="fee"
        [options]="feeOptions"
        [displayWith]="feeDisplayWith"
        (searchChange)="setFeeOptionsSubject.next($event)"
        width="wide"
      >
      </payee-autocomplete>
      <ng-container payee-error *ngIf="fee.dirty">
        <div *ngIf="fee.hasError('required')">A mező kitöltése kötelező!</div>
      </ng-container>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button (click)="submit()" payee-button width="wide">Mentés</button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      (click)="cancel()"
      color="white"
      [isBorderButton]="true"
      width="wide"
    >
      Mégsem
    </button>
  </ng-template>
</payee-popup>
