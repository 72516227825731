import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { AxiosError } from 'axios';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { MissingRefIdItem, SzamlazzhuInvoiceGroup } from 'src/app/services/szamlazzhu/types';

@Component({
  selector: 'app-set-invoice-dialog',
  templateUrl: './set-invoice-dialog.component.html',
  styleUrls: ['./set-invoice-dialog.component.scss']
})
export class SetInvoiceDialogComponent implements OnInit {
  readonly dataSource = new MatTableDataSource<SzamlazzhuInvoiceGroup>();
  page = 0;
  perPage = 10;
  length = Infinity;
  selectedInvoice?: SzamlazzhuInvoiceGroup;

  readonly displayedColumns = [
    'remaining_amount',
    'original_amount',
    'due_date_at',
    'invoice_number',
    'select',
  ];

  constructor(
    private dialogRef: MatDialogRef<SetInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: MissingRefIdItem,
    private szamlazzhuService: SzamlazzhuService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setInvoices({
      length: this.length,
      pageIndex: this.page,
      pageSize: this.perPage,
    });
  }

  async setInvoices(event: PageEvent): Promise<void> {
    this.page = event.pageIndex;
    this.perPage = event.pageSize;

    try {
      const result = await this.szamlazzhuService.getInvoiceFormDataTable(
        this.data.payee_case_reference_id,
        {
          page: this.page,
          per_page: this.perPage,
        }
      );
      this.dataSource.data = result.invoices;
      this.length = result.total;

      if (!this.selectedInvoice && result.invoices.length > 0) {
        this.selectedInvoice = result.invoices[0];
      }
    } catch (error) {
      console.error('Error while getting invoices', error);
    }
  }

  selectInvoice(item: SzamlazzhuInvoiceGroup): void {
    this.selectedInvoice = item;
  }

  readonly submit = async () => {
    if (!this.selectedInvoice) {
      return;
    }

    try {
      await this.szamlazzhuService.setMissingRefId(this.data.uuid, this.selectedInvoice.invoice.id);
      this.dialogRef.close();
    } catch (error: AxiosError | unknown) {
      console.error('Error while setting invoice id', error);
    }
  };

  readonly close = () => {
    this.dialogRef.close();
  };
}
