<payee-popup
  id="missing-ref-id-popup"
  title="Számla kiválasztása"
  [onCancel]="close"
  [onSubmit]="submit"
>
  <div class="flex" id="missing-ref-id-container">
    <ul id="missing-ref-id-data" class="grow1">
      <h2>Követelés</h2>
      <li>
        Fennálló követelés: {{ data.remaining_amount | number : "1.0" : "hu" }}
        {{ data.currency_iso }}
      </li>
      <li>
        Teljes követelés: {{ data.original_amount | number : "1.0" : "hu" }}
        {{ data.currency_iso }}
      </li>
      <li>Fizetési határidő: {{ data.due_date_at | date : "yyyy.MM.dd." }}</li>
      <li>Szémla PDF neve: {{ data.invoice_file_name }}</li>
    </ul>
    <ul id="missing-ref-id-selected" class="grow1">
      <h2>Kiválasztott számla</h2>
      <li>
        Fennálló követelés:
        {{ selectedInvoice?.invoice.remaining_amount | number : "1.0" : "hu" }}
        {{ selectedInvoice?.invoice.currency_iso }}
      </li>
      <li>
        Teljes követelés:
        {{ selectedInvoice?.invoice.original_amount | number : "1.0" : "hu" }}
        {{ selectedInvoice?.invoice.currency_iso }}
      </li>
      <li>
        Fizetési határidő:
        {{ selectedInvoice?.invoice.due_date_at | date : "yyyy.MM.dd." }}
      </li>
      <li>Számla neve: {{ selectedInvoice?.invoice.invoice_number }}</li>
    </ul>
  </div>

  <table
    id="missing-ref-id-invoice-table"
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="remaining_amount">
      <th mat-header-cell *matHeaderCellDef>Fennálló követelés</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoice.remaining_amount | number : "1.0" : "hu" }}
        {{ element.invoice.currency_iso }}
      </td>
    </ng-container>

    <ng-container matColumnDef="original_amount">
      <th mat-header-cell *matHeaderCellDef>Teljes követelés</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoice.original_amount | number : "1.0" : "hu" }}
        {{ element.invoice.currency_iso }}
      </td>
    </ng-container>

    <ng-container matColumnDef="due_date_at">
      <th mat-header-cell *matHeaderCellDef>Fizetési határidő</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoice.due_date_at | date : "yyyy.MM.dd." }}
      </td>
    </ng-container>

    <ng-container matColumnDef="invoice_number">
      <th mat-header-cell *matHeaderCellDef>invoice_number</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoice.invoice_number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>Kiválasztás</th>
      <td mat-cell *matCellDef="let element">
        <mat-radio-button
          [checked]="element.invoice.id === selectedInvoice?.invoice.id"
          (change)="selectInvoice(element)"
        ></mat-radio-button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    id="missing-ref-id-invoice-paginator"
    [pageIndex]="page"
    [pageSize]="perPage"
    [length]="length"
    showFirstLastButtons
    (page)="setInvoices($event)"
  ></mat-paginator>
</payee-popup>
