<ng-template
  #menuDisplayTemplate
  let-selected
>
  <span *ngIf="!!selected">{{ displayWith(selected) }}</span>
  <span *ngIf="!selected" [attr.color]="disabled ? 'grey-medium' : 'grey-dark'">Válassz...</span>
</ng-template>

<ng-template
  #itemDisplayTemplate
  let-option
>
  <span class="value">{{ displayWith(option) }}</span>
</ng-template>

<ng-template #menuButtonTemplate>
  <button
    type="button"
    payee-button
    [style.--width]="'100%'"
    [height]="height"
    [color]="color"
    [isBorderButton]="true"
		[disabled]="disabled"
  >
    <ng-container
      [ngTemplateOutlet]="menuDisplayTemplateRef || menuDisplayTemplate"
      [ngTemplateOutletContext]="{ $implicit: selected }"
    ></ng-container>
  </button>
  <img
    payee-suffix
    src="assets/shared/global-images/icon-set/arrow-down-grey-no-shaft.svg"
  />
</ng-template>

<ng-content select="[payee-label]"></ng-content>
<div
  class="payee-input-wrapper"
  [class.error]="error"
  [attr.width]="width"
  [attr.height]="height"
  #inputWrapper
>
  <div
    [matMenuTriggerFor]="menu"
    [class.menu-disabled]="disabled"
  >
    <ng-container [ngTemplateOutlet]="menuButtonTemplateRef || menuButtonTemplate"></ng-container>
  </div>

  <mat-menu
    #menu
    xPosition="after"
    class="payee-dropdown-menu-panel"
  >
    <div class="options-content" [attr.width]="width">
      <button
        mat-menu-item
        (click)="selected = option; onChange(option)"
        *ngFor="let option of options"
        [style.width]="inputWrapper.clientWidth"
      >
        <ng-container
          [ngTemplateOutlet]="itemDisplayTemplateRef || itemDisplayTemplate"
          [ngTemplateOutletContext]="{ $implicit: option }"
        ></ng-container>
      </button>
    </div>
  </mat-menu>
</div>
<div
  class="errors-box"
  [class.error]="error"
  #errorsBox
>
  <ng-content select="[payee-error]"></ng-content>
</div>