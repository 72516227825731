import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeLanguageSelectorComponent } from './payee-language-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PayeeFormFieldsModule } from '../payee-form-fields/payee-form-fields.module';
import { PayeeButtonsModule } from '../payee-buttons/payee-buttons.module';

@NgModule({
	declarations: [
		PayeeLanguageSelectorComponent,
	],
	imports: [
		CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    PayeeFormFieldsModule,
    PayeeButtonsModule,
	],
	exports: [
		PayeeLanguageSelectorComponent,
	],
})
export class PayeeLanguageSelectorModule { }
