<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="house"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>{{ "WORKFLOW_DIALOGS.FULL_PAYMENT_WARNING.TITLE" | translate }}</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      {{ "WORKFLOW_DIALOGS.FULL_PAYMENT_WARNING.DESCRIPTION" | translate }}
    </div>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="max"
      height="normal"
      [mat-dialog-close]="true"
    >
      {{ "WORKFLOW_DIALOGS.FULL_PAYMENT_WARNING.CONFIRM" | translate }}
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="max"
      height="normal"
    >
      <ng-container>
        {{ "WORKFLOW_DIALOGS.FULL_PAYMENT_WARNING.CANCEL" | translate }}
      </ng-container>
    </button>
  </ng-template>
</payee-popup>
