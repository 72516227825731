<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="house"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>{{ "WORKFLOW_DIALOGS.ADD_DELIVERY_ADDRESS.TITLE" | translate }}</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      {{ "WORKFLOW_DIALOGS.ADD_DELIVERY_ADDRESS.DESCRIPTION" | translate }}
      <span>{{
        "WORKFLOW_DIALOGS.ADD_DELIVERY_ADDRESS.NO_DATA" | translate
      }}</span>
    </div>
  </ng-template>

  <div class="old-address input-label-small" color="grey-medium">
    <span>{{
      "WORKFLOW_DIALOGS.ADD_DELIVERY_ADDRESS.CURRENT_INCORRECT_ADDRESS"
        | translate
    }}</span
    >&nbsp;
    <strong>{{ dialogData?.old_address }}</strong>
  </div>

  <payee-form-field [width]="'wider'" [height]="'taller'">
    <textarea
      payee-input
      [placeholder]="
        'WORKFLOW_DIALOGS.ADD_DELIVERY_ADDRESS.PLACEHOLDER' | translate
      "
      [formControl]="newAddressForm"
    ></textarea>
  </payee-form-field>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <ng-container>{{
        "WORKFLOW_DIALOGS.ADD_DELIVERY_ADDRESS.SUBMIT" | translate
      }}</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>{{
        "WORKFLOW_DIALOGS.ADD_DELIVERY_ADDRESS.CANCEL" | translate
      }}</ng-container>
    </button>
  </ng-template>
</payee-popup>
