import { Injectable } from '@angular/core';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';

export type GetUserResponse = {
  name: string;
  can_update_product_price: boolean;
  email: string;
};

export type UserDataTableItem = {
  user_uuid: string;
  email: string;
  user_name: string;
  roles: {
    name: string;
  }[];
  attorney_countries: {
    iso: string;
    name: string;
  }[];
  phone_number: string | null;
  clients: {
    name: string;
    id: string;
  }[];
};

export type UserDataTableParams = {
  user_email?: string;
  page?: number;
  per_page?: number;
  sort_by?: keyof UserDataTableItem;
  sort_direction?: 'asc' | 'desc';
};

export type UserDataTableHeader = {
  title: string;
  align: 'start' | 'center';
  key?: keyof UserDataTableItem;
};

export type UserDataTableResponse = {
  headers: UserDataTableHeader[];
  sortBy: unknown[];
  data: UserDataTableItem[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
  };
};

export type CreateUserParams = {
  email: string;
  name: string;
  phone?: string;
  roles: string[];
  attorney_countries: string[];
  client_ids: string[];
};

export type UpdateUserParams = {
  email: string;
  name: string;
  phone?: string;
  roles: string[];
  client_ids: string[];
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly userSubject = new BehaviorSubject<GetUserResponse | null>(null);

  constructor(
    private authService: AuthService,
  ) {
    this.authService.authSubject
      .subscribe({
        next: async isLoggedIn => {
          if (isLoggedIn) {
            const user = await this.setUser();
            this.userSubject.next(user);
          } else {
            this.userSubject.next(null);
          }
        },
      });
  }

  async getUser(): Promise<GetUserResponse> {
    return await new Promise(resolve => this.userSubject
      .pipe(filter(user => !!user))
      .subscribe({
        next: (u: GetUserResponse) => resolve(u),
      }),
    );
  }

  async getUsersDataTable(params: UserDataTableParams): Promise<UserDataTableResponse> {
    const url = environment.baseUrl + '/api/user-datatable';
    const result = await axios.get<UserDataTableResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }

  async createUser(params: CreateUserParams): Promise<GetUserResponse> {
    const url = environment.baseUrl + `/api/user`;
    const result = await axios.post<GetUserResponse>(url, params);
    return result.data;
  }

  async updateUser(uuid: string, params: CreateUserParams): Promise<GetUserResponse> {
    const url = environment.baseUrl + `/api/user/${uuid}`;
    const result = await axios.post<GetUserResponse>(url, {
      ...params,
      '_method': 'PUT',
    });
    return result.data;
  }

  private async setUser(): Promise<GetUserResponse> {
    const url = environment.baseUrl + `/api/user`;
    const result = await axios.get<GetUserResponse>(url);
    return result.data;
  }
}
