<form [formGroup]="formGroup" *ngIf="client && formGroup">
  <payee-form-field width="wide">
    <input payee-input formControlName="name" />
    <span payee-label>Név</span>
  </payee-form-field>

  <div class="data-row-label">Bankszámla</div>
  <span class="data-row-label"
    >Kedvezményezett neve:
    {{ client.bank_account.account_holder_name || "-" }}</span
  >
  <span class="data-row-label"
    >IBAN: {{ client.bank_account.iban || "-" }}</span
  >
  <span class="data-row-label"
    >BBAN: {{ client.bank_account.account_number || "-" }}</span
  >
  <span class="data-row-label">BIC: {{ client.bank_account.bic || "-" }}</span>

  <span class="data-row-label">Cím</span>
  <div class="flex">
    <!-- Country -->
    <payee-form-field width="short">
      <payee-autocomplete
        formControlName="country"
        [options]="countryOptions"
        [displayWith]="countryDisplayWith"
      >
      </payee-autocomplete>
      <span payee-label>Ország</span>
    </payee-form-field>
    <!-- Postcode -->
    <payee-form-field width="short">
      <input payee-input formControlName="postcode" />
      <span payee-label>Irányítószám</span>
    </payee-form-field>
  </div>
  <payee-form-field width="wide">
    <input payee-input formControlName="settlement" />
    <span payee-label>Település</span>
  </payee-form-field>
  <payee-form-field width="wide">
    <input payee-input formControlName="street" />
    <span payee-label>Utca</span>
    <ng-container *ngIf="formGroup.get('street').invalid">
      <mat-error *ngIf="formGroup.get('street').hasError('required')">
        Mező kitöltése kötelező
      </mat-error>
      <mat-error *ngIf="formGroup.get('street').hasError('maxlength')">
        A mező legfeljebb
        {{ formGroup.get("street").errors?.maxlength.requiredLength }} karakter
        lehet
      </mat-error>
    </ng-container>
  </payee-form-field>

  <span class="data-row-label">Hitelező adószáma: {{ client.tax_number }}</span>
  <span class="data-row-label">
    Hitelező nyilvántartási száma: {{ client.registration_number }}</span
  >

  <payee-form-field width="wide">
    <span payee-label>Hitelező típusa</span>
    <payee-autocomplete
      [formControl]="partyTypeId"
      [options]="partyTypeOptions"
      [displayWith]="partyTypeDisplayWith"
      placeholder="Válassz típust!"
    >
    </payee-autocomplete>
  </payee-form-field>

  <span class="data-row-label">Hitelező elérhetősége</span>

  <div class="flex vcenter client-contact-form">
    <span class="data-row-title">Hitelező email címe</span>
    <payee-form-field>
      <input payee-input [formControl]="email" />
      <span payee-label>Email cím</span>
    </payee-form-field>
    <button
      type="button"
      payee-icon-button
      color="white"
      (click)="email.patchValue(null)"
    >
      <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
    </button>
  </div>
  <div class="flex vcenter client-contact-form">
    <span class="data-row-title">Hitelező telefonszáma</span>
    <payee-form-field>
      <input payee-input [formControl]="phone" />
      <span payee-label>Telefonszám</span>
    </payee-form-field>
    <button
      type="button"
      payee-icon-button
      color="white"
      (click)="phone.patchValue(null)"
    >
      <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
    </button>
  </div>

  <form *ngIf="clientHasRepresentative" [formGroup]="representative">
    <span class="data-row-label">
      Képviselő: {{ client.representative.name }}
    </span>

    <div class="flex column">
      <payee-form-field width="wide">
        <span payee-label>Képviselő típusa</span>
        <payee-autocomplete
          [formControl]="representativeType"
          [options]="representativeTypeOptions"
          [displayWith]="repTypeDisplayWith"
        >
        </payee-autocomplete>
      </payee-form-field>
      <payee-form-field width="wide">
        <input payee-input [formControl]="representativePhone" />
        <span payee-label>Képviselő telefonszáma</span>
      </payee-form-field>
      <payee-form-field width="wide">
        <input payee-input [formControl]="representativeEmail" />
        <span payee-label>Képviselő email címe</span>
      </payee-form-field>

      <span class="data-row-label">Cím</span>
      <div class="flex">
        <!-- Country -->
        <payee-form-field width="short">
          <payee-autocomplete
            [formControl]="repCountry"
            [options]="countryOptions"
            [displayWith]="countryDisplayWith"
          >
          </payee-autocomplete>
          <span payee-label>Ország</span>
        </payee-form-field>
        <!-- Postcode -->
        <payee-form-field width="short">
          <input payee-input [formControl]="repPostcode" />
          <span payee-label>Irányítószám</span>
        </payee-form-field>
      </div>
      <payee-form-field width="wide">
        <input payee-input [formControl]="repSettlement" />
        <span payee-label>Település</span>
      </payee-form-field>
      <payee-form-field width="wide">
        <input payee-input [formControl]="repStreet" />
        <span payee-label>Utca</span>
        <ng-container *ngIf="repStreet.invalid">
          <mat-error *ngIf="repStreet.hasError('required')">
            Mező kitöltése kötelező
          </mat-error>
          <mat-error *ngIf="repStreet.hasError('maxlength')">
            A mező legfeljebb
            {{ repStreet.errors?.maxlength.requiredLength }}
            karakter lehet
          </mat-error>
        </ng-container>
      </payee-form-field>
    </div>
  </form>
</form>
