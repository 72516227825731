<table
  id="missing-ref-id-table"
  mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z1"
>
  <ng-container matColumnDef="remaining_amount">
    <th mat-header-cell *matHeaderCellDef>Fennálló követelés</th>
    <td mat-cell *matCellDef="let element">
      {{ element.remaining_amount | number : "1.0" : "hu" }}
      {{ element.currency_iso }}
    </td>
  </ng-container>

  <ng-container matColumnDef="original_amount">
    <th mat-header-cell *matHeaderCellDef>Teljes követelés</th>
    <td mat-cell *matCellDef="let element">
      {{ element.original_amount | number : "1.0" : "hu" }}
      {{ element.currency_iso }}
    </td>
  </ng-container>

  <ng-container matColumnDef="due_date_at">
    <th mat-header-cell *matHeaderCellDef>Fizetési határidő</th>
    <td mat-cell *matCellDef="let element">
      {{ element.due_date_at | date : "yyyy.MM.dd." }}
    </td>
  </ng-container>

  <ng-container matColumnDef="invoice_file_name">
    <th mat-header-cell *matHeaderCellDef>Számla PDF neve</th>
    <td mat-cell *matCellDef="let element">{{ element.invoice_file_name }}</td>
  </ng-container>

  <ng-container matColumnDef="set_invoice">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button
        payee-button
        width="short"
        height="short"
        (click)="openSetInvoiceDialog(element)"
      >
        Számla kiválasztása
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator
  id="missing-ref-id-invoice-paginator"
  [pageIndex]="page"
  [pageSize]="perPage"
  [length]="length"
  showFirstLastButtons
  (page)="setClaims($event)"
></mat-paginator>
