import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Product, ProductService } from 'src/app/services/product-service/product-service.service';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { EditProductDialogComponent } from './edit-product-dialog/edit-product-dialog.component';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { CountryProduct, CountryProductService } from 'src/app/services/country-product/country-product.service';
import { AxiosError } from 'axios';
import { MatSort } from '@angular/material/sort';
import { DeleteProductDialogComponent } from './delete-product-dialog/delete-product-dialog.component';
import { Vat, VatService } from 'src/app/services/vat/vat.service';

@Component({
  selector: 'app-product-prices',
  templateUrl: './product-prices.component.html',
  styleUrls: ['./product-prices.component.scss']
})
export class ProductPricesComponent implements OnInit, OnDestroy {
  get userName(): string { return this.usersService.userSubject.value?.name ?? ''; }
  readonly dataSource = new MatTableDataSource<Product>();
  readonly displayedColumns = [
    'name',
    'type',
    'currency_iso',
    'activation',
    'edit',
    'delete',
  ];
  loading = false;

  private readonly destroy = new Subject<void>();

  private vats: Vat[] = [];
  vatOptions: Vat[] = [];

  countryOptions: CountryProduct[] = [];
  countryControl = new FormControl();
  get selectedCountry(): CountryProduct | undefined { return this.countryControl.value; }

  @ViewChild(MatSort) set sort(sort: MatSort) { this.dataSource.sort = sort; }

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private productService: ProductService,
    private countryProductService: CountryProductService,
    private dialog: MatDialog,
    private vatService: VatService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.countryControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        filter(v => !!v),
        distinctUntilChanged(),
      )
      .subscribe(async (country: CountryProduct) => {
        await this.setProducts(country);
        this.vatOptions = this.vats.filter(v => v.country_iso === country.iso);
      });

    const [
      countries,
      vats,
    ] = await Promise.all([
      this.countryProductService.getCountries().catch(e => {
        console.error('Error while getting country options', e);
        throw e;
      }),
      this.vatService.getVats().catch(e => {
        console.error('Error while getting vats', e);
        throw e;
      }),
    ]);

    this.countryOptions = countries;
    this.vats = vats;

    const hu = this.countryOptions.find(c => c.iso === 'HU');
    this.countryControl.setValue(hu);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  editProduct(product?: Product): void {
    const ref = this.dialog.open(EditProductDialogComponent, {
      data: {
        product,
        country: this.countryControl.value,
        vats: this.vatOptions,
      },
    });
    ref.afterClosed()
      .pipe(first(), takeUntil(this.destroy))
      .subscribe({
        next: async (result?: Product) => {
          if (result) {
            if (product) {
              Object.assign(product, result);
            } else {
              this.dataSource.data = this.dataSource.data.concat(result);
            }
          }
        },
      });
  }

  deleteProduct(product: Product): void {
    const ref = this.dialog.open(DeleteProductDialogComponent, {
      data: product,
    });

    ref.afterClosed()
      .pipe(first(), takeUntil(this.destroy))
      .subscribe({
        next: async (result?: boolean) => {
          if (result) {
            const index = this.dataSource.data.findIndex(p => p.id === product.id);
            this.dataSource.data.splice(index, 1);
            this.dataSource.data = this.dataSource.data;
          }
        },
      });
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  canActivate(product: Product): boolean {
    return this.dataSource.data.every(p =>
      p.id === product.id
      || p.type !== product.type
      || !p.is_active
    );
  }

  countryDisplayWith(country?: CountryProduct): string {
    return country?.name ?? '';
  }

  async setProductIsActive(toggled: boolean, product: Product): Promise<void> {
    try {
      if (toggled) {
        await this.productService.activateProduct(product);
      } else {
        await this.productService.deactivateProduct(product);
      }
      product.is_active = toggled;
    } catch (error) {
      console.error('Error while changing is active of product', error);
    }
  }

  async setCountryIsFormActive(toggled: boolean): Promise<void> {
    if (!this.selectedCountry) {
      return;
    }

    try {
      if (toggled) {
        await this.countryProductService.activateForm(this.selectedCountry);
      } else {
        await this.countryProductService.deactivateForm(this.selectedCountry);
      }
      this.countryControl.patchValue({
        ...this.selectedCountry,
        is_form_active: toggled,
      }, {
        emitEvent: false,
      });
    } catch (error) {
      console.error('Error while changing is form active of country', error);
    }
  }

  private async setProducts(country: CountryProduct): Promise<void> {
    try {
      this.loading = true;
      this.countryControl.disable();

      const result = await this.productService.getProducts(country);
      this.dataSource.data = result.products;
    } catch (error) {
      console.error('Error while getting products', error);
    } finally {
      this.loading = false;
      this.countryControl.enable();
    }
  }
}
