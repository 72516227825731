import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type InterestClaim = {
  id: string | null;
  remaining_amount: number | null;
  original_amount: number | null;
};

export type ClaimMissingOptionalClaims = {
  id: string;
  due_date_at: string | null;
  remaining_amount: number;
  original_amount: number;
};

export type FlatRateCostClaim = {
  id: string | null;
  remaining_amount: number | null;
  original_amount: number | null;
};

export type GetMissingOptionalClaimsResponse = {
  payee_case: {
    base_remaining_amount: number;
    base_original_amount: number;
  };
  interest_claim: InterestClaim | null;
  flat_rate_cost_claims: {
    parent: ClaimMissingOptionalClaims;
    flat_rate_cost_claim: FlatRateCostClaim;
  }[] | null;
};

type UpdateMissingOptionalClaimsParams = {
  interest_claim?: {
    amount: number,
  };
  flat_rate_cost_claims?: ({
    parent_id: string,
    amount: number | null,
  })[];
};

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  async getAllowedClaimTypes(legalRelationshipId: number): Promise<number[]> {
    const url = environment.baseUrl + `/api/allowed-claim-types/${legalRelationshipId}`;
    const result = await axios.get<number[]>(url);

    return result.data;
  }

  async getMissingOptionalClaims(caseId: string): Promise<GetMissingOptionalClaimsResponse> {
    const url = `${environment.baseUrl}/api/missing-optional-claims/${caseId}`;
    const result = await axios.get(url);
    return result.data;
  }

  async updateMissingOptionalClaims(caseId: string, params: UpdateMissingOptionalClaimsParams): Promise<void> {
    const url = `${environment.baseUrl}/api/missing-optional-claims/${caseId}`;
    await axios.post(url, params);
  }
}
