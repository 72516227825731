import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { serialize } from 'object-to-formdata';
import { UpdateClientParams } from '../clients/clients.service';

export type GetCasesParams = {
  client_id?: string;
  debtor_name?: string;
  payee_case_id?: string;
  has_missing_optional_claims?: 1 | 0;
  page?: number;
  per_page?: number;
};

export type CasesTableData = {
  id: string;
  payee_case_reference_id: string;
  is_deleted: boolean;
  jurisdiction: string;
  partner: string | null;
  parties: {
    debtor_name: string | null;
    client_name: string;
  };
  state: {
    stage: string;
    state_label_text_color: string;
    state_label_background_color: string;
    state_label: string;
    stage_label: string;
  };
  claim: {
    remaining_amount: number;
    original_amount: number;
    currency: string;
  };
  actions: {
    id: string;
    label: string;
    dialog: string;
  }[];
};

export type CasesTableHeader = {
  title: string;
  align: 'center' | 'start';
  key?: keyof CasesTableData;
};

export type GetCasesResponse = {
  headers: CasesTableHeader[];
  // TODO
  sortBy: never[];
  data: CasesTableData[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
  };
};

export type LegalRelationshipFile = {
  id: number;
  file_name: string;
  storage_url: string;
};

export type GetEditCaseResponse = {
  payee_case: {
    product_type: 'notice'
    | 'demand_letter'
    | 'hard_1'
    | 'hard_2';
    legal_relationship_id: number;
    legal_relationship_files: LegalRelationshipFile[];
    legal_relationship_specification: string;
    legal_relationship_explanation: string;
    description: string;
  };
  claims: {
    id: string;
    files: {
      id: number;
      file_name: string;
      storage_url: string;
      invoice_number: string | null;
      invoice_date: string | null;
    }[];
    due_date_at: string;
    original_amount: number;
    remaining_amount: number;
    claim_type_id: number | null;
    claim_type_specification: string | null;
  }[];
  client: {
    name: string;
    address: {
      country_id: number | null;
      postcode: string | null;
      settlement: string | null;
      street: string | null;
    };
    tax_number: string | null;
    registration_number: string | null;
    party_type_id: number | null;
    email: string | null;
    phone: string | null;
    representative: {
      name: string | null;
      representative_type_id: number | null;
      phone: string | null;
      email: string | null;
      address: {
        country_id: number | null;
        postcode: string | null;
        settlement: string | null;
        street: string | null;
      };
    };
    bank_account: {
      account_holder_name: string | null;
      iban: string | null;
      account_number: string | null;
      bic: string | null;
    };
  };
  debtors: {
    id: string;
    name: string;
    address: {
      country_id: number | null;
      postcode: string | null;
      settlement: string | null;
      street: string | null;
    };
    tax_number: string | null;
    registration_number: string | null;
    party_type_id: number | null;
    email: string | null;
    phone: string | null;
    representative: {
      name: string | null;
      representative_type_id: number | null;
      phone: string | null;
      email: string | null;
      address: {
        country_id: number | null;
        postcode: string | null;
        settlement: string | null;
        street: string | null;
      };
    };
  }[];
};

export type UpdateCaseParams = {
  claims: {
    id?: string;
    claim_type_id: number;
    claim_type_specification?: string;
    amount: number;
    due_date_at?: string;
    file?: File;
    file_id?: number;
    invoice_number?: string;
    invoice_date?: string;
  }[];
  debtors: {
    id: string;
    party_type_id: number;
    email?: string;
    phone?: string;
    representative?: {
      email?: string;
      phone?: string;
      representative_type_id: number;
      address?: {
        country_id?: number;
        postcode?: string;
        settlement?: string;
        street?: string;
      };
    };
    address: {
      country_id: number;
      postcode: string;
      settlement: string;
      street: string;
    };
  }[];
  client: UpdateClientParams;
  files?: {
    uuid?: number;
    file_name?: string;
    file?: File;
  }[];
  legal_relationship_id?: number;
  legal_relationship_specification?: string;
  description?: string;
};

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  async getCases(params: GetCasesParams): Promise<GetCasesResponse> {
    const url = environment.baseUrl + '/api/case';
    const result = await axios.get<GetCasesResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }

  async getCase(caseId: string): Promise<GetCasesResponse> {
    const url = environment.baseUrl + `/api/case/${caseId}`;
    const result = await axios.get<GetCasesResponse>(url);

    return result.data;
  }

  async getEditCaseData(caseId: string): Promise<GetEditCaseResponse> {
    const url = environment.baseUrl + `/api/case/${caseId}`;
    const result = await axios.get<GetEditCaseResponse>(url);

    return result.data;
  }

  async updateCase(caseId: string, params: UpdateCaseParams): Promise<GetEditCaseResponse> {
    const url = environment.baseUrl + `/api/case/${caseId}`;
    const formData = serialize(params, {
      indices: true,
    });
    formData.append('_method', 'PUT');
    const result = await axios.post<GetEditCaseResponse>(url, formData);

    return result.data;
  }

  async copyCaseWithProduct(caseId: string, productId: number): Promise<CasesTableData> {
    const url = environment.baseUrl + `/api/copy-case-with-product/${caseId}/${productId}`;
    const result = await axios.post<CasesTableData>(url);

    return result.data;
  }

  async getAllowedLegalRelationships(claim_type_ids: number[]): Promise<number[]> {
    const queryParms = claim_type_ids.map(id => `claim_type_ids[]=${id}`).join('&');
    const url = environment.baseUrl + `/api/allowed-legal-relationship?${queryParms}`;
    const result = await axios.get<number[]>(url);

    return result.data;
  }

  async restoreCase(caseId: string): Promise<void> {
    const url = environment.baseUrl + `/api/case/${caseId}/restore`;
    await axios.post(url);
  }
}
