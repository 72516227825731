<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <img
      src="assets/shared/global-images/icons/cross-grey-circle.svg"
      height="71.43px" />
    <h1>{{ 'WORKFLOW_DIALOGS.CLOSE_CASE.TITLE' | translate }}</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <span class="description">
      {{ 'WORKFLOW_DIALOGS.CLOSE_CASE.DESCRIPTION' | translate }}
    </span>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit('paid')">
      <ng-container>
        {{ 'WORKFLOW_DIALOGS.CLOSE_CASE.PAID' | translate }}
      </ng-container>
    </button>
    <button
      class="payee-button"
      payee-button
      color="grey-dark"
      width="wide"
      height="normal"
      (click)="submit('cancel')">
      <ng-container>
        {{ 'WORKFLOW_DIALOGS.CLOSE_CASE.OTHER_REASON' | translate }}
      </ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      (click)="cancel()"
      color="white"
      width="wide"
      height="normal">
      <ng-container>
        {{ 'WORKFLOW_DIALOGS.CLOSE_CASE.CANCEL' | translate }}
      </ng-container>
    </button>
  </ng-template>
</payee-popup>
