<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <h1 class="title top-margin-20">
      {{ "WORKFLOW_DIALOGS.DEBTOR_IND_DATA.TITLE" | translate }}
    </h1>
  </ng-template>

  <div class="help-text">
    <div>
      {{ "WORKFLOW_DIALOGS.DEBTOR_IND_DATA.DESCRIPTION" | translate }}
    </div>
    <div class="top-margin-20">
      <span>{{
        "WORKFLOW_DIALOGS.DEBTOR_IND_DATA.NO_DATA_INFO.PART1" | translate
      }}</span>
      <a>{{
        "WORKFLOW_DIALOGS.DEBTOR_IND_DATA.NO_DATA_INFO.LINK_TEXT" | translate
      }}</a>
      <span>{{
        "WORKFLOW_DIALOGS.DEBTOR_IND_DATA.NO_DATA_INFO.PART2" | translate
      }}</span>
    </div>
  </div>

  <form [formGroup]="form" class="top-margin-10">
    <payee-form-field width="wide" height="taller">
      <input
        payee-input
        [placeholder]="
          'WORKFLOW_DIALOGS.DEBTOR_IND_DATA.MOTHER_NAME.PLACEHOLDER' | translate
        "
        formControlName="mother_name"
      />
      <ng-container
        payee-error
        *ngIf="mother_name.invalid && mother_name.dirty"
      >
        <span *ngIf="mother_name.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="mother_name.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>

    <payee-form-field width="wide" height="taller">
      <input
        payee-input
        [placeholder]="
          'WORKFLOW_DIALOGS.DEBTOR_IND_DATA.BIRTH_PLACE.PLACEHOLDER' | translate
        "
        formControlName="birth_place"
      />
      <ng-container
        payee-error
        *ngIf="birth_place.invalid && birth_place.dirty"
      >
        <span *ngIf="birth_place.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="birth_place.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>

    <payee-form-field width="wide" height="taller">
      <payee-datepicker
        [max]="max"
        [placeholder]="
          'WORKFLOW_DIALOGS.DEBTOR_IND_DATA.BIRTH_DATE.PLACEHOLDER' | translate
        "
        formControlName="birth_date"
      ></payee-datepicker>
      <ng-container payee-error *ngIf="birth_date.invalid && birth_date.dirty">
        <span *ngIf="birth_date.hasError('required')">
          {{ "ERROR_MESSAGES.FIELD_REQUIRED" | translate }}
        </span>
        <span *ngIf="birth_date.hasError('maxlength')">
          {{ "ERROR_MESSAGES.MAXLENGTH" | translate }}
        </span>
      </ng-container>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button payee-button width="wide" (click)="submit()">
      <div class="flex vcenter hcenter">
        <span>{{ "WORKFLOW_DIALOGS.DEBTOR_IND_DATA.SUBMIT" | translate }}</span>
        <payee-icon name="arrow-right-white" height="10px"></payee-icon>
      </div>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      payee-button
      width="wide"
      mat-dialog-close
      color="white"
      [isBorderButton]="true"
    >
      <div class="flex vcenter hcenter">
        <span>{{ "WORKFLOW_DIALOGS.DEBTOR_IND_DATA.CANCEL" | translate }}</span>
        <payee-icon name="arrow-right-grey" height="10px"></payee-icon>
      </div>
    </button>
  </ng-template>
</payee-popup>
