import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AxiosError } from 'axios';
import { SzamlazzhuService } from 'src/app/services/szamlazzhu/szamlazzhu.service';
import { MissingRefIdItem } from 'src/app/services/szamlazzhu/types';
import { SetInvoiceDialogComponent } from './set-invoice-dialog/set-invoice-dialog.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-missing-ref-ids',
  templateUrl: './missing-ref-ids.component.html',
  styleUrls: ['./missing-ref-ids.component.scss']
})
export class MissingRefIdsComponent implements OnInit {
  page: number = 0;
  perPage: number = 10;
  length = Infinity;

  readonly displayedColumns = [
    'remaining_amount',
    'original_amount',
    'due_date_at',
    'invoice_file_name',
    'set_invoice',
  ];
  readonly dataSource = new MatTableDataSource<MissingRefIdItem>();

  constructor(
    private szamlazzhuService: SzamlazzhuService,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setClaims({
      length: this.length,
      pageIndex: this.page,
      pageSize: this.perPage,
    });
  }

  async setClaims(event: PageEvent): Promise<void> {
    this.page = event.pageIndex;
    this.perPage = event.pageSize;
    try {
      const result = await this.szamlazzhuService.getMissingRefIds({
        page: this.page + 1,
        per_page: this.perPage,
      });
      this.dataSource.data = result.data;
      this.length = result.pagination.total;
    } catch (error: AxiosError | unknown) {
      console.error('Error while getting missing ref ids', error);
    }
  }

  openSetInvoiceDialog(item: MissingRefIdItem): void {
    this.dialog.open(SetInvoiceDialogComponent, {
      data: item,
    });
  }
}
