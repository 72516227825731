import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type CountryProduct = {
  iso: string;
  name: string;
  is_form_active: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class CountryProductService {
  async getCountries(): Promise<CountryProduct[]> {
    const url = `${environment.baseUrl}/api/country-product`;
    const result = await axios.get(url);
    return result.data.countries;
  }

  async activateForm(country: CountryProduct): Promise<void> {
    const url = `${environment.baseUrl}/api/country-product/${country.iso}/activate`;
    await axios.post(url);
  }

  async deactivateForm(country: CountryProduct): Promise<void> {
    const url = `${environment.baseUrl}/api/country-product/${country.iso}/deactivate`;
    await axios.post(url);
  }
}
