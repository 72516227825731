import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Product, ProductService } from 'src/app/services/product-service/product-service.service';

@Component({
  selector: 'app-delete-product-dialog',
  templateUrl: './delete-product-dialog.component.html',
  styleUrls: ['./delete-product-dialog.component.scss']
})
export class DeleteProductDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private product: Product,
    private dialogRef: MatDialogRef<DeleteProductDialogComponent>,
    private productService: ProductService,
  ) { }

  async submit(): Promise<void> {
    try {
      await this.productService.deleteProduct(this.product);
      this.dialogRef.close(true);
    } catch (error) {
      console.error('Error while deleting product');
    }
  }
}
