import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CasesTableData } from 'src/app/services/cases/cases.service';
import { ClaimMissingOptionalClaims, ClaimsService, FlatRateCostClaim, InterestClaim } from 'src/app/services/claims/claims.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';

@Component({
  selector: 'app-fix-missing-optional-claims-dialog',
  templateUrl: './fix-missing-optional-claims-dialog.component.html',
  styleUrls: ['./fix-missing-optional-claims-dialog.component.scss']
})
export class FixMissingOptionalClaimsDialogComponent implements OnInit {
  readonly form: FormGroup;

  interestClaimData?: InterestClaim;
  claims: Record<string, {
    parent: ClaimMissingOptionalClaims;
    flat_rate_cost_claim: FlatRateCostClaim;
  }> = {};
  remaining_amount?: number;
  original_amount?: number;

  get interest_claim(): FormControl | undefined { return this.form.get('interest_claim') as FormControl | undefined; }
  get flat_rate_cost_claims(): FormGroup | undefined { return this.form.get('flat_rate_cost_claims') as FormGroup | undefined; }

  constructor(
    private fb: FormBuilder,
    private claimsService: ClaimsService,
    @Inject(MAT_DIALOG_DATA) public readonly data: CasesTableData,
    private dialogRef: MatDialogRef<FixMissingOptionalClaimsDialogComponent>,
    private helpersService: HelpersService,
  ) {
    this.form = this.fb.group({
      flat_rate_cost_claims: this.fb.group({}),
    });
  }

  async ngOnInit(): Promise<void> {
    const result = await this.claimsService.getMissingOptionalClaims(this.data.id);

    this.remaining_amount = result.payee_case.base_remaining_amount;
    this.original_amount = result.payee_case.base_original_amount;

    if (result.interest_claim) {
      this.interestClaimData = result.interest_claim;
      this.form.addControl(
        'interest_claim',
        this.fb.control(result.interest_claim.remaining_amount, [Validators.min(1)]),
      );
    }

    if (result.flat_rate_cost_claims) {
      this.form.addControl(
        'flat_rate_cost_claims',
        this.fb.group({}),
      );
      for (const { flat_rate_cost_claim, parent } of result.flat_rate_cost_claims) {
        this.addFlatRateCostClaim(parent, flat_rate_cost_claim);
        this.claims[parent.id] = {
          flat_rate_cost_claim,
          parent,
        };
      }
    }
  }

  readonly submit = async () => {
    if (this.form.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.form);
      return;
    }

    try {
      this.form.disable();

      await this.claimsService.updateMissingOptionalClaims(
        this.data.id,
        {
          flat_rate_cost_claims: this.flat_rate_cost_claims
            ? Object.entries(this.flat_rate_cost_claims?.controls)
              .map(([parent_id, control]) => ({
                amount: control.value,
                parent_id,
              }))
            : undefined,
          interest_claim: this.interest_claim
            ? {
              amount: this.interest_claim.value,
            }
            : undefined,
        }
      );

      this.dialogRef.close(true);
    } catch (error) {
      console.error('Error while saving missing optional claims', error);
    } finally {
      this.form.enable();
    }
  };

  readonly cancel = () => this.dialogRef.close();

  private addFlatRateCostClaim(parent: ClaimMissingOptionalClaims, claim: FlatRateCostClaim): void {
    this.flat_rate_cost_claims?.addControl(
      parent.id,
      this.fb.control(claim?.remaining_amount, [Validators.min(1)]),
    );
  }
}
