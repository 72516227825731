import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type Vat = {
  id: number;
  percentage: number;
  label: string;
  country_iso: string;
};

@Injectable({
  providedIn: 'root'
})
export class VatService {

  constructor() { }

  async getVats(): Promise<Vat[]> {
    const url = `${environment.baseUrl}/api/vat`;
    const result = await axios.get(url);
    return result.data.vats;
  }
}
