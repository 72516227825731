import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { CountryProduct } from '../country-product/country-product.service';

export type GetProductDiscountsParams = {
  page: number;
  per_page: number;
  sort_by?: string;
  sort_direction?: string;
};

export type ProductDiscount = {
  id: number;
  country: CountryProduct;
  discount: number;
  discount_type: 'amount' | 'percentage';
  conditions: unknown;
  product: {
    id: number;
    name: string;
    type: string;
    currency_iso: string;
  };
  fee: {
    id: string;
    label: string;
  };
  product_discountable: {
    type: 'partner' | 'client';
    id: string;
    name: string;
  };
};

export type GetProductDiscountsResponse = {
  data: ProductDiscount[];
  headers: unknown[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
  };
  sortBy: [];
};

export type UpdateProductDiscountParams = {
  product_discountable_type: 'client' | 'partner';
  product_discountable_id: string;
  discount_type: 'amount' | 'percentage';
  discount: number;
  fee_id: number;
};

export type UpdateProductDiscountResponse = GetProductDiscountsResponse;

export type CreateProductDiscountParams = UpdateProductDiscountParams;
export type CreateProductDiscountResponse = GetProductDiscountsResponse;

@Injectable({
  providedIn: 'root'
})
export class ProductDiscountService {
  async getProductDiscounts(params: GetProductDiscountsParams): Promise<GetProductDiscountsResponse> {
    const url = `${environment.baseUrl}/api/product-discount-datatable`;
    const result = await axios.get<GetProductDiscountsResponse>(url, { params });
    return result.data;
  }

  async createProductDiscount(params: UpdateProductDiscountParams): Promise<UpdateProductDiscountResponse> {
    const url = `${environment.baseUrl}/api/product-discount`;
    const result = await axios.post<UpdateProductDiscountResponse>(url, params);
    return result.data;
  }

  async updateProductDiscount(id: number, params: UpdateProductDiscountParams): Promise<UpdateProductDiscountResponse> {
    const url = `${environment.baseUrl}/api/product-discount/${id}`;
    const result = await axios.post<UpdateProductDiscountResponse>(url, {
      ...params,
      '_method': 'PUT',
    });
    return result.data;
  }

  async deleteProductDiscount(id: number): Promise<void> {
    const url = `${environment.baseUrl}/api/product-discount/${id}`;
    await axios.delete(url);
  }
}
