<form
  *ngFor="let debtorForm of debtors.controls; let i = index"
  [formGroup]="debtorForm"
>
  <div class="data-row-title">
    {{ debtorsData[i].name }}
  </div>

  <span class="data-row-label">Cím</span>
  <div class="flex">
    <!-- Country -->
    <payee-form-field width="short">
      <payee-autocomplete
        formControlName="country"
        [options]="countryOptions"
        [displayWith]="countryDisplayWith"
      >
      </payee-autocomplete>
      <span payee-label>Ország</span>
    </payee-form-field>
    <!-- Postcode -->
    <payee-form-field width="short">
      <input payee-input formControlName="postcode" />
      <span payee-label>Irányítószám</span>
    </payee-form-field>
  </div>
  <payee-form-field width="wide">
    <input payee-input formControlName="settlement" />
    <span payee-label>Település</span>
  </payee-form-field>
  <payee-form-field width="wide">
    <input payee-input formControlName="street" />
    <span payee-label>Utca</span>
    <ng-container *ngIf="debtorForm.get('street').invalid">
      <mat-error *ngIf="debtorForm.get('street').hasError('required')">
        Mező kitöltése kötelező
      </mat-error>
      <mat-error *ngIf="debtorForm.get('street').hasError('maxlength')">
        A mező legfeljebb
        {{ debtorForm.get("street").errors?.maxlength.requiredLength }} karakter
        lehet
      </mat-error>
    </ng-container>
  </payee-form-field>

  <span class="data-row-label">
    Adós adószáma: {{ debtorsData[i].tax_number }}
  </span>
  <span class="data-row-label">
    Adós nyilvántartási száma: {{ debtorsData[i].registration_number }}
  </span>

  <payee-form-field width="wide">
    <payee-autocomplete
      formControlName="party_type_id"
      [options]="partyTypeOptions"
      [displayWith]="partyTypeDisplayWith"
    >
    </payee-autocomplete>
    <span payee-label>Adós típusa</span>
  </payee-form-field>

  <span class="data-row-label"> Elérhetőségek </span>

  <div class="flex vcenter debtor-contact-form">
    <span class="data-row-title">Adós email címe</span>
    <payee-form-field>
      <input payee-input [formControl]="debtorForm.get('email')" />
      <span payee-label>Email cím</span>
    </payee-form-field>
    <button
      type="button"
      payee-icon-button
      color="white"
      (click)="debtorForm.get('email').patchValue(null)"
    >
      <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
    </button>
  </div>
  <div class="flex vcenter debtor-contact-form">
    <span class="data-row-title">Adós telefonszáma</span>
    <payee-form-field>
      <input payee-input [formControl]="debtorForm.get('phone')" />
      <span payee-label>Telefonszám</span>
    </payee-form-field>
    <button
      type="button"
      payee-icon-button
      color="white"
      (click)="debtorForm.get('phone').patchValue(null)"
    >
      <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
    </button>
  </div>

  <ng-container *ngIf="debtorsData[i].representative.name">
    <span class="data-row-label"> Képviselő </span>

    <form formGroupName="representative" class="flex column">
      <div class="data-row-title">
        {{ debtorsData[i].representative.name }}
      </div>

      <payee-form-field width="wide">
        <span payee-label>Képviselő típusa</span>
        <payee-autocomplete
          [options]="representativeTypeOptions"
          formControlName="representative_type_id"
          [displayWith]="repTypeDisplayWith"
        >
        </payee-autocomplete>
        <ng-container
          payee-error
          *ngIf="
            debtorForm.get('representative').get('representative_type_id')
              .invalid
          "
        >
          A mező kitöltése kötelező!
        </ng-container>
      </payee-form-field>
      <payee-form-field width="wide">
        <input payee-input formControlName="phone" />
        <span payee-label>Képviselő telefonszáma</span>
      </payee-form-field>
      <payee-form-field width="wide">
        <input payee-input formControlName="email" />
        <span payee-label>Képviselő email címe</span>
      </payee-form-field>

      <span class="data-row-label">Cím</span>
      <form formGroupName="address">
        <div class="flex">
          <!-- Country -->
          <payee-form-field width="short">
            <payee-autocomplete
              formControlName="country"
              [options]="countryOptions"
              [displayWith]="countryDisplayWith"
            >
            </payee-autocomplete>
            <span payee-label>Ország</span>
          </payee-form-field>
          <!-- Postcode -->
          <payee-form-field width="short">
            <input payee-input formControlName="postcode" />
            <span payee-label>Irányítószám</span>
          </payee-form-field>
        </div>
        <payee-form-field width="wide">
          <input payee-input formControlName="settlement" />
          <span payee-label>Település</span>
        </payee-form-field>
        <payee-form-field width="wide">
          <input payee-input formControlName="street" />
          <span payee-label>Utca</span>
          <ng-container *ngIf="debtorForm.get('representative.address.street').invalid">
            <mat-error *ngIf="debtorForm.get('representative.address.street').hasError('required')">
              Mező kitöltése kötelező
            </mat-error>
            <mat-error *ngIf="debtorForm.get('representative.address.street').hasError('maxlength')">
              A mező legfeljebb
              {{ debtorForm.get("representative.address.street").errors?.maxlength.requiredLength }}
              karakter lehet
            </mat-error>
          </ng-container>
        </payee-form-field>
      </form>
    </form>
  </ng-container>
</form>
