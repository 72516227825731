import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { CountryProduct } from '../country-product/country-product.service';

export type Fee = {
  id: number;
  label: string;
  fee: number;
  min: number | null;
  max: number | null;
  type: string;
  is_net: boolean;
  payment_occurrence: string;
  fee_type: string;
  vat_id: number;
};

export type Product = {
  id: number;
  name: string;
  type: string;
  currency_iso: string;
  is_active: boolean;
  is_deletable: boolean;
  fees: Fee[];
};

export type GetProductResponse = {
  products: Product[];
};

export type CreateProductParams = Omit<Product, 'id'>;
export type UpdateProductParams = Product;

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  async getProducts(country: CountryProduct): Promise<GetProductResponse> {
    const url = `${environment.baseUrl}/api/product/${country.iso}`;
    const result = await axios.get<GetProductResponse>(url);
    return result.data;
  }

  async activateProduct(product: Product): Promise<void> {
    const url = `${environment.baseUrl}/api/product/${product.id}/activate`;
    await axios.post(url);
  }

  async deactivateProduct(product: Product): Promise<void> {
    const url = `${environment.baseUrl}/api/product/${product.id}/deactivate`;
    await axios.post(url);
  }

  async createProduct(country: CountryProduct, params: CreateProductParams): Promise<Product> {
    const url = `${environment.baseUrl}/api/product/${country.iso}`;
    const result = await axios.post<{ product: Product; }>(url, params);
    return result.data.product;
  }

  async updateProduct(product: Product, params: UpdateProductParams): Promise<void> {
    const url = `${environment.baseUrl}/api/product/${product.id}`;
    await axios.post(url, {
      ...params,
      '_method': 'PUT',
    });
  }

  async deleteProduct(product: Product): Promise<void> {
    const url = `${environment.baseUrl}/api/product/${product.id}`;
    await axios.delete(url);
  }
}
