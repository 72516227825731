import { Component, Inject, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AxiosError } from 'axios';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CountryProduct } from 'src/app/services/country-product/country-product.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { Fee, Product, ProductService } from 'src/app/services/product-service/product-service.service';
import { Vat } from 'src/app/services/vat/vat.service';

@Component({
  selector: 'app-edit-product-dialog',
  templateUrl: './edit-product-dialog.component.html',
  styleUrls: ['./edit-product-dialog.component.scss']
})
export class EditProductDialogComponent implements OnDestroy {
  readonly formGroup: FormGroup;
  readonly product?: Product;
  readonly country: CountryProduct;
  readonly vatOptions: Vat[];
  private readonly destroy = new Subject<void>();

  submitLoading = false;

  readonly productTypeOptions = [
    'notice',
    'demand_letter',
    'hard_1',
    'hard_2',
  ];

  readonly feeTypeOptions = [
    'amount',
    'percentage',
  ];

  readonly feePaymentOccurrenceOptions = [
    'start',
    'closed-success',
  ];

  readonly feeFeeTypeOptions = [
    'indirect',
    'payee',
  ];

  get name(): FormControl { return this.formGroup.get('name') as FormControl; };
  get type(): FormControl { return this.formGroup.get('type') as FormControl; };
  get currency_iso(): FormControl { return this.formGroup.get('currency_iso') as FormControl; };
  get fees(): FormArray { return this.formGroup.get('fees') as FormArray; };

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: {
      product: Product | undefined,
      country: CountryProduct,
      vats: Vat[],
    },
    private dialogRef: MatDialogRef<EditProductDialogComponent>,
    private productService: ProductService,
    private helpersService: HelpersService,
  ) {
    this.product = data.product;
    this.country = data.country;
    this.vatOptions = data.vats;

    this.formGroup = this.fb.group({
      name: [this.product?.name, [Validators.required, Validators.maxLength(255)]],
      type: [this.product?.type, [Validators.required]],
      currency_iso: [this.product?.currency_iso, [Validators.minLength(3), Validators.maxLength(3)]],
      fees: this.fb.array([]),
    });
    this.product?.fees.forEach(fee => this.addFee(fee));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async submit(): Promise<void> {
    if (this.submitLoading || this.formGroup.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.fees);
      return;
    }

    try {
      this.submitLoading = true;

      if (this.product) {
        await this.productService.updateProduct(this.product, this.formGroup.value);
        this.dialogRef.close(this.formGroup.value);
      } else {
        const result = await this.productService.createProduct(this.country, this.formGroup.value);
        this.dialogRef.close(result);
      }

    } catch (error: AxiosError | unknown) {
      console.error('Error while updating product price', error);
    } finally {
      this.submitLoading = false;
    }
  }

  removeFee(i: number): void {
    this.fees.removeAt(i);
  }

  addFee(fee?: Fee): void {
    if (!fee && this.fees.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.fees);
      return;
    }

    const feeForm = this.fb.group({
      id: fee?.id,
      label: [fee?.label, [Validators.required, Validators.maxLength(255)]],
      fee: [fee?.fee, [Validators.required]],
      type: [fee?.type, [Validators.required]],
      is_net: !!fee?.is_net,
      payment_occurrence: [fee?.payment_occurrence, [Validators.required]],
      fee_type: [fee?.fee_type, [Validators.required]],
      vat_id: [fee?.vat_id, [Validators.required]],
      min: fee?.min,
      max: fee?.max,
    });

    feeForm.get('vat_id')?.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe((vat?: number | Vat) => {
        if (vat && typeof vat === 'object' && 'id' in vat) {
          feeForm.patchValue({
            vat_id: vat.id,
          });
        }
      });

    this.fees.push(feeForm);
  }

  readonly vatDisplayedWith = (vat?: number | Fee): string => {
    if (typeof vat === 'number') {
      return this.vatOptions.find(v => v.id === vat)?.label ?? '';
    }
    return vat?.label ?? '';
  };
}
