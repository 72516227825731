import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'payee-language-selector',
  templateUrl: './payee-language-selector.component.html',
  styleUrls: ['./payee-language-selector.component.scss'],
})
export class PayeeLanguageSelectorComponent implements OnInit, OnDestroy {
  @Input() color = 'white';
  @Input() isBorderButton = true;
  private readonly langKey = 'lang' as const;
  private readonly destroy = new Subject<void>();
  readonly languageControl = new FormControl(this.translate.currentLang);

  get currentLang(): string { return this.translate.currentLang; }
  get langs(): string[] { return this.translate.langs; }

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.languageControl.valueChanges
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged(),
      )
      .subscribe((lang: string) => {
        this.translate.use(lang);
        localStorage.setItem(this.langKey, lang);
      });

    this.translate.onLangChange
      .pipe(takeUntil(this.destroy))
      .subscribe(langChangeEvent => {
        this.languageControl.patchValue(langChangeEvent.lang, { emitEvent: false });
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
