<div class="payee-button-wrapper">
  <ng-container *ngIf="!loading">
    <ng-content></ng-content>
  </ng-container>

  <div *ngIf="loading" class="loading-wrapper">
    <mat-progress-spinner color="accent" mode="indeterminate" diameter="20"></mat-progress-spinner>
  </div>
</div>

<span
  payee-ripple
  [payeeRippleDisabled]="disableRipple"
  [payeeRippleTrigger]="element"
  [attr.color]="color"
  [class.payee-round-button-ripple]="isRoundButton"
></span>
