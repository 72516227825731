import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayeeHeaderComponent } from './payee-header.component';
import { PayeeVerticalLineModule } from '../payee-vertical-line/payee-vertical-line.module';
import { PayeeLanguageSelectorModule } from '../payee-language-selector/payee-language-selector.module';

@NgModule({
	declarations: [
		PayeeHeaderComponent,
	],
	imports: [
		CommonModule,
		PayeeVerticalLineModule,
    PayeeLanguageSelectorModule,
	],
	exports: [
		PayeeHeaderComponent,
	],
})
export class PayeeHeaderModule { }
