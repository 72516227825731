import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { ClientsService, ListClientsParams } from 'src/app/services/clients/clients.service';
import { CountryProduct, CountryProductService } from 'src/app/services/country-product/country-product.service';
import { FeesService, ListFeesParams } from 'src/app/services/fees/fees.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ListPartnersParams, PartnerService } from 'src/app/services/partner/partner.service';
import { ProductDiscount, ProductDiscountService } from 'src/app/services/product-discount/product-discount.service';
import { ProductService } from 'src/app/services/product-service/product-service.service';

@Component({
  selector: 'app-create-product-discount-dialog',
  templateUrl: './create-product-discount-dialog.component.html',
  styleUrls: ['./create-product-discount-dialog.component.scss']
})
export class CreateProductDiscountDialogComponent implements OnInit, OnDestroy {
  readonly formGroup: FormGroup;
  submitLoading = false;

  readonly productDiscountableTypeOptions: ProductDiscount['product_discountable']['type'][] = [
    'client',
    'partner',
  ];
  productDiscountableOptions: ProductDiscount['product_discountable'][] = [];
  readonly setProductDiscountableOptionsSubject = new Subject<string>();
  productDiscountableOptionsLoading = false;

  readonly discountTypeOptions: ProductDiscount['discount_type'][] = [
    'amount',
    'percentage',
  ];

  countryOptions: CountryProduct[] = [];

  feeOptions: ProductDiscount['fee'][] = [];
  readonly setFeeOptionsSubject = new Subject<string>();
  feeOptionsLoading = false;

  productOptions: ProductDiscount['product'][] = [];

  private readonly destroy = new Subject<void>();

  get country(): FormControl { return this.formGroup.get('country') as FormControl; }
  get selectedCountry(): CountryProduct | null { return this.country.value; }
  get product_discountable_type(): FormControl { return this.formGroup.get('product_discountable_type') as FormControl; }
  get product_discountable(): FormControl { return this.formGroup.get('product_discountable') as FormControl; }
  get discount(): FormControl { return this.formGroup.get('discount') as FormControl; }
  get discount_type(): FormControl { return this.formGroup.get('discount_type') as FormControl; }
  get fee(): FormControl { return this.formGroup.get('fee') as FormControl; }
  get product(): FormControl { return this.formGroup.get('product') as FormControl; }

  constructor(
    private fb: FormBuilder,
    private ref: MatDialogRef<CreateProductDiscountDialogComponent>,
    private clientsService: ClientsService,
    private partnerService: PartnerService,
    private productService: ProductService,
    private productDiscountService: ProductDiscountService,
    private countryProductService: CountryProductService,
    private feesService: FeesService,
    private helpersService: HelpersService,
  ) {
    this.formGroup = this.fb.group({
      country: null,
      product_discountable_type: ['client', Validators.required],
      product_discountable: [null, Validators.required],
      discount: [null, [Validators.required, Validators.min(0)]],
      discount_type: ['amount', [Validators.required]],
      fee: [null, Validators.required],
      product: [null, Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    this.countryOptions = await this.countryProductService.getCountries();
    const hu = this.countryOptions.find(c => c.iso === 'HU');
    this.country.patchValue(hu);

    this.country.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(async () => {
        await this.setProductOptions();
        this.product.setValue(null);
      });

    this.product_discountable_type.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: () => {
          this.productDiscountableOptions = [];
          this.product_discountable.patchValue(null);
        },
      });

    this.product.valueChanges
      .pipe(
        takeUntil(this.destroy),
      )
      .subscribe({
        next: () => {
          this.fee.patchValue(null);
          this.setFeeOptionsSubject.next();
        }
      });

    this.setProductDiscountableOptionsSubject
      .pipe(
        takeUntil(this.destroy),
        filter(v => !v || v.length >= 3),
        debounceTime(500),
      )
      .subscribe({
        next: async filter => await this.setProductDiscountableOptions(filter)
      });

    this.setFeeOptionsSubject
      .pipe(
        takeUntil(this.destroy),
        filter(v => !v || v.length >= 3),
        debounceTime(500),
      )
      .subscribe({
        next: async filter => await this.setFeeOptions(filter)
      });

    await this.setProductOptions();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  productDiscountableDisplayWith(discountable?: ProductDiscount['product_discountable']): string {
    return discountable?.name ?? '';
  };

  productDisplayWith(product?: ProductDiscount['product']): string {
    return product?.name ?? '';
  };

  feeDisplayWith(fee?: ProductDiscount['fee']): string {
    return fee?.label ?? '';
  };

  countryProductDisplayWith(country?: CountryProduct): string {
    return country?.name ?? '';
  }

  async submit(): Promise<void> {
    if (this.submitLoading) {
      return;
    }
    if (this.formGroup.invalid) {
      this.helpersService.markAllChildrenAsDirty(this.formGroup);
      return;
    }

    try {
      this.submitLoading = true;

      const response = await this.productDiscountService.createProductDiscount({
        discount: this.discount.value,
        discount_type: this.discount_type.value,
        fee_id: this.fee.value.id,
        product_discountable_id: this.product_discountable.value.id,
        product_discountable_type: this.product_discountable_type.value,
      });

      this.ref.close(response);
    } catch (error) {
      console.error('Error while saving product discount', error);
    } finally {
      this.submitLoading = false;
    }
  }

  cancel(): void {
    this.ref.close();
  }

  private async setProductDiscountableOptions(filter?: string) {
    const loadTimeout = setTimeout(() => this.productDiscountableOptionsLoading = true, 200);
    try {
      if (this.product_discountable_type.value === 'client') {
        const params: ListClientsParams = {};
        if (filter) {
          params.name = filter;
        }
        const result = await this.clientsService.listClients(params);
        this.productDiscountableOptions = result.clients.map(c => ({
          ...c,
          type: 'client',
        }));
      } else {
        const params: ListPartnersParams = {};
        if (filter) {
          params.name = filter;
        }
        const result = await this.partnerService.listPartners(params);
        this.productDiscountableOptions = result.partners.map(p => ({
          ...p,
          type: 'partner',
        }));
      }
    } catch (error) {
      console.error('Error while getting product discountables', {
        type: this.product_discountable_type.value,
        error,
      });
    } finally {
      clearTimeout(loadTimeout);
      this.productDiscountableOptionsLoading = false;
    }
  }

  private async setProductOptions(): Promise<void> {
    if (!this.selectedCountry) {
      return;
    }
    try {
      const response = await this.productService.getProducts(this.selectedCountry);
      this.productOptions = response.products.map(p => ({
        currency_iso: p.currency_iso,
        id: p.id,
        name: p.name,
        type: p.type,
      }));
    } catch (error) {
      console.error('Error while getting products', error);
    }
  }

  private async setFeeOptions(filter?: string): Promise<void> {
    if (!this.product.value) {
      return;
    }

    const loadTimeout = setTimeout(() => this.feeOptionsLoading = true, 200);
    try {
      const params: ListFeesParams = {
        product_id: this.product.value.id,
      };
      if (filter) {
        params.label = filter;
      }
      const result = await this.feesService.listFees(params);
      this.feeOptions = result.fees;
    } catch (error) {
      console.error('Error while getting fees', error);
    } finally {
      clearTimeout(loadTimeout);
      this.feeOptionsLoading = false;
    }
  }
}
